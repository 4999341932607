/* ============================ Price List Css  ============================*/
.pricing-page .pricing-table-widget { height: auto;}
#formatSizingTable .surface-sizing-table-custom  {background: #fdfdfd;}
.mobile-column-heading { font-size: 13px; font-weight: 700; display: none ;min-height: 32px;color: #222324;}
/*.select-product-by-size { -webkit-align-items: center; align-items: center; -webkit-justify-content: center; justify-content: center; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; padding: 0; position: relative; }*/
.select-product-by-size {padding: 0; position: relative;min-width: 150px;}
.select-product-by-size .issale { position: absolute; left: 0px; top: 0; z-index: 9 }
.select-product-by-size.issale {  left: 0px;  z-index: 0;  top: 0;  position: absolute;}
.issale img{width: 24px;height: 24px;}
/*.select-product-by-size .product-price { font-size: 13px; font-weight: 400; border: 2px solid transparent; border-radius: 15px; padding: 0 7px; display: inline-block; margin: 0; min-width: 100px; line-height: 22px; text-align: center }*/
.select-product-by-size .product-price { font-size: 13px; font-weight: 400;}
/*.select-product-by-size .product-price.sale-price { border-color: #863c35 }*/
.add-to-quote-mini{border-radius: 16px; font-size: 10px; line-height: 13px; font-weight: 700; float: right; width: 82px; height: 21px; padding: 0;  background: #f5f5f5; color: #aca7a780; box-shadow: none;}
.add-to-quote-mini:hover {opacity: 1; background: #eaeaea; color: #222324; }





/* ============================ Price List Css  ============================*/
