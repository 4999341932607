
@media screen and (min-width:2100px) {
  .page-banner-section {min-height: 720px !important;}
  .video-section .smiles-video .video-container{max-width: 1920px !important;float: inherit !important;}
  .video-section .smiles-video { /*background: #313131 !important;*/float: inherit !important;display: flex;justify-content: center;}
  .surface-explore, .decore-explore {min-height: 500px;}
  .projects-image {height: 500px !important;}
  .owner-image img {height: auto;}
  .container-custom  {background: #f5efea;}
  .container-custom .two-shade-section.gatherco-credit-section  {max-width: 1920px !important;margin: 0 auto;}
  .container-custom .two-shade-section.gatherco-credit-section .two-shade-content {padding: 80px 0 80px 100px;}
  .resource-container.section-inner-container {max-width: 1920px !important;}
  .all-in-one {min-height:inherit !important;}
  .featured-product-inner,
  .make-us-container,
  /*.service-list-inner-container,*/
  .all-in-one-container {max-width: 1920px;margin: 0 auto;}
  .make-us-different {min-height: inherit !important;}
  /*.service-image-bg img {height: auto !important;}*/
  .feature-product-item {height: 500px !important;background-position: top center !important;}
  .client-carousel-container {max-width: 1920px !important;margin: 0 auto;}
  .two-shade-section.gatherco-credit-section.home-gatherco-credit-wrap {min-height: inherit !important;}
  .earn-value-content.go-commit-left-text {max-width: 700px;margin: 0 auto;}
  /*.explore-expanded .decore-explore,*/
  /*.explore-expanded .surface-explore {min-height: 700px !important;}*/
}
@media screen and (min-width:2600px) {
  .page-banner-section {min-height: 1080px !important;}
  .surface-explore, .decore-explore {min-height: 600px;}
  .projects-image {height: 600px !important;}
  .feature-product-item {height: 600px !important;}
}
@media screen and (min-width:3000px) {
  .page-banner-section {min-height: 1200px !important;}
  .surface-explore, .decore-explore {min-height: 600px;}
  .projects-image {height: 600px !important;}
  .feature-product-item {height: 700px !important;}
}
@media screen and (min-width:3600px) {
  .page-banner-section {min-height: 1400px !important;}
  .surface-explore, .decore-explore {min-height: 700px;}
  .projects-image {height: 700px !important;}
  .feature-product-item {height: 800px !important;}
}
@media screen and (min-width:4000px) {
  .page-banner-section {min-height: 1600px !important;}
  .surface-explore, .decore-explore {min-height: 800px;}
  .projects-image {height: 800px !important;}
  .feature-product-item {height: 900px !important;}
}
@media screen and (min-width:1921px) {
    .product-slider-navigation { left: 67.85vw }
}
@media screen and (min-width:1200px) {
    .widget-item.left-panel { max-width: calc(100% - 293px); padding-right: 18px }
}
@media screen and (min-width:1500px) {
    .profile-form { max-width: 100% }
    .filter-product-grid .product-grop-grid>ul>li { width: 20% }
}
@media screen and (max-width: 1800px) {
    .faq-section{padding: 70px 35px 20px;}
}
@media screen and (max-width: 1700px) {
    .product-slider-navigation{left: 67vw!important;}
}
@media screen and (max-width:1560px) {
    .product-slider-navigation .product-slider-arrow { position: relative; top: 2px }
    .imageEnlarge .product-gallery-image{height: auto;}
    .two-shade-content {padding: 80px 50px 80px 94px;}
    .gather-commitment-two-shade .two-shade-content{padding-left: 84px;}
    .right-content .article-list{margin-bottom: 15px!important;}
    .product-slider-navigation { left: 66.5vw!important; }
}
@media screen and (max-width:1513px) {
    .need-more-help{margin-bottom: 35px!important;}
    .faq-section{padding: 70px 35px 0px;}
}
@media screen and (max-width: 1500px) {
    .product-gallery-detail .product-color-palette-list ul li .color-palette-thumb {height: 26px;}
    .product-gallery-detail .product-color-palette-list ul li .color-palette-thumb a {width: 24px!important;height: 24px!important;}
    .product-gallery-detail .product-color-palette-list ul li .color-palette-thumb .color-palette-name {height: 24px;line-height: 24px;}
    .product-gallery-detail .color-palette-name-inner {height: 24px;}
    .color-palette-thumb a img, .color-thumb a img {width: 24px !important;height: 24px !important;min-width: 24px;min-height: 24px;}
    .color-palette-name-inner {height: 24px; line-height: 22px;}
    .product-color-palette{left: 10px;}
}

@media screen and (max-width: 1200px) {
  .btn {font-size: 12px;}
}
@media screen and (min-width:1101px) {
    .hide-in-desktop { display: none !important }
    .show-in-desktop { display: block !important }
    .signup-inner-container .sign-up-title br { display: none }
}
@media screen and (min-width:992px) {
    .logo.mobile-logo, .mobile-panel-active, .mobile-panel-close, .mobile-panel-price, .mobile-table-collapse, .available-product-pagination { display: none }
}
@media screen and (min-width:768px) {
    .logo.mobile-logo { display: none }
    .product-list-pagination { display: none }
    .enlarge-close, .image-enlarge-pagination { display: none }
    .textureGuideModal .modal-header .title-lexend-26, .slipRating .modal-header .title-lexend-26, .finishesModal .modal-header .title-lexend-26, .ratingGuideModal .modal-header .title-lexend-26, #gatherCoCredit .modal-header .title-lexend-26 { display: none }
    .favourite-slide-inner-wrapper .swiper-pagination { display: none }
    .prices-list-icon .notification-item a { color: #fff }
    .prices-list-icon .notification-item a .count { background: #fff; color: #000 }
}
@media screen and (max-width:1499px) {
    .two-shade-content { padding: 80px 25px 80px 95px; width: 100% }
}
@media screen and (max-width:1440px) {
    .search-widget { min-width: 100px }
    .favourite-product-list>ul>li:nth-child(4n) ul.dropdown-menu { margin-left: -140px }
    .favourite-product-list>ul>li { max-width: 100% }
    .color-item .color-pellete { width: 50px; height: 50px }
    .product-option-right .specification-cion { width: 39px; height: 39px; line-height: 39px; font-size: 16px }
    .specification-box { margin-right: 15px }
    .fixedscroll-section .faq-box { max-width: 100% }
    .product-label { width: 35px }
    .section-inner-container { padding: 0 50px }
}

@media screen and (min-width:1101px) and (max-width:1199px) {
    .pricing-page-main-table .pricing-table-widget .format-sizing-table table td .mobile-column-heading { display: block !important }
    .pricing-page-main-table .pricing-table-widget .format-sizing-table table td:first-child .mobile-column-heading { margin: 0; padding-left: 75px }
    .pricing-page-main-table .pricing-table-widget .format-sizing-table table td { padding: 10px 0; border-bottom: 1px solid #e8ebed; height: auto }
}
@media screen and (max-width:1199px) {
    .mega-menu-modal.modal{display: none !important;}
    .format-sizing-table ul li{line-height: normal;}
}
@media screen and (max-width:1100px) {
    .info-table .table td .circle-view-btn, .circle-view-btn { border: 2px solid #222324 !important; float: right !important; margin-right: -5px !important }
    .new-credir-earn-bottom.two-col-leadtime .leadtime.delievry-leadtime { margin-left: 0px }
    .navbar-toggler { padding: 0; margin: 0 0 0 15px; line-height: 0; outline: none !important }
    .navbar-toggler span { width: 20px; height: 2px; background: #222324; display: block; margin: 3px 0; border-radius: 35px }
    .navbar-collapse { position: absolute; left: 0; top: 50px; width: 100%; background: #fff; border-radius: 5px; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); max-height: calc(100vh - 80px); overflow: auto }
    /*.sample-detail-bottom ul li, .samples-table.sample-tab .sample-detail-bottom ul li { -webkit-box-flex: 0; -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50% }*/
    .widget-item.right-panel { margin-top: 37px; padding-left: 15px }
    .progress-step::before { font-size: 20px }
    .section-inner-container { padding: 0 40px }
    .earn-value-content label { font-size: 40px }
    .home-gatherco-credit-wrap .earn-value-content label { line-height: 48px }
    .texture-guide-left { padding: 35px 35px; border-right: 1px solid #e8ebed; width: 400px }
    .texture-guide-right { width: calc(100% - 430px) }
    .favourite-save { opacity: 1 }
    .board-project-list ul { margin-right: 0 }
    .mobile-column-heading { display: block !important; line-height: 18px; font-weight: 700 }
    .mobile-column-heading small { font-weight: 700; font-size: 10px }
    .mobile-table-collapse { line-height: 0 }
    .mobile-table-collapse span { font-size: 16px }
    .mobile-table-collapse { display: inline-block; margin-right: 10px }
    .active-col .mobile-table-collapse { display: none }
    .favourite-btn { opacity: 1 !important; visibility: visible !important }
    .product-enlarge-gallery .product-gallery-image img { height: auto; width: 100% }
    .enlarge-image-options { right: 20px }
    .imageEnlarge .modal-dialog .modal-content { min-height: 100vh; display: flex; align-items: center; justify-content: center }
    .product-enlarge-gallery-container { max-width: 70% }
    .product-option-left { width: 100%; margin-bottom: 20px }
    .product-rating-items { justify-content: center }
    .available-stock-right .nav-tabs { margin-right: 0 }
    .info-table .table tr { display: flex; flex-wrap: wrap; justify-content: space-between }
    .info-table .table thead th { flex-grow: 1 }
    .info-table .table tr th, .info-table .table tr td { flex-grow: 1; width: auto !important; min-width: auto !important; text-align: left !important; font-size: 15px }
    .info-table .table th:first-child, .info-table .table td:first-child { max-width: 55% !important }
    .info-table .table td h6 { max-width: 95%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden }
    .info-table .table .expiry-date { max-width: 65px; min-width: 65px; font-size: 20px; margin: 0 }
    .info-table .table .expiry-date span { margin-left: 0px }
    .sample-detail-bottom.in-two-col ul { margin: 0 -15px }
    .sample-detail-bottom.in-two-col { padding-left: 15px; padding-right: 15px }
    .sample-detail-bottom.in-two-col ul li { flex: 0 0 50%; max-width: 50%; padding: 0 5px 0 15px }
    .sample-detail-inner img { margin-right: 11.5px }
    .info-table.info-table-order .table tr th:not(:first-child) { display: none }
    .info-table.info-table-order .table th:first-child { max-width: 100% !important }
    .info-table.info-table-order .table tr { border-top: 1px solid #ebebeb }
    .info-table.info-table-order>.table-responsive>.table>tbody>tr { padding-top: 10px; padding-bottom: 10px }
    .info-table.info-table-order .table td { border-top: none; width: 50% !important }
    .info-table.info-table-order .table td:first-child { order: -2; max-width: 80% !important; width: auto !important }
    .info-table.info-table-order .table td:first-child p { display: none }
    .info-table.info-table-order .table td:last-child { order: 0; width: auto !important; text-align: right !important }
    .info-table-order .table td label { font-size: 11px; color: #aab0b1; text-transform: uppercase }
    .info-table.info-table-order .btn-view a { border: 2px solid #222324; color: #222324; height: 28px; min-width: 76px; font-size: 11px; border-radius: 21px; line-height: 25px; text-align: center; display: inline-block }
    .tab-title-main { font-size: 25px; font-weight: normal; color: #222324; margin-bottom: 22px }
    .order-stat-table-title { text-align: center; margin-bottom: 20px }
    .order-table-header-left { position: relative; padding-left: 34px; font-size: 11px; flex: 0 0 65%; max-width: 65% }
    .order-table-header-left span { position: absolute; left: 0; top: 50%; transform: translateY(-50%) }
    .order-table-header-right { flex: 0 0 25%; max-width: 25%; display: flex; flex-direction: column; text-align: center }
    .day-btn { margin-left: 0; min-width: 65px; margin-bottom: 10px; margin-top: 7px }
    .order-table-header-right span { order: 2; font-size: 10px }
    .order-table-project-wrapper { border-bottom: 1px solid #ebebeb }
    .order-table-project-col { border-top: 1px solid #ebebeb }
    .order-table-project-col>* { padding-top: 15px; padding-bottom: 15px }
    .order-table-project-col .border-right { border-right: 1px solid #ebebeb }
    .order-table-project-detail { min-height: auto }
    .order-table-project-detail h4 { font-size: 18px;flex: 1;}
    .order-table-action-column .order-number { height: auto !important; line-height: normal !important; padding: 5px !important; float: right !important }
    .order-table-project-wrapper .warehouse-arrival { border: none; margin: 0 }
    .order-table-project-wrapper .warehouse-arrival .arrival-date { font-size: 12px }
    .quotes-active-wrapper { padding: 0 }
    .quotes-active-inner { padding: 15px }
    .quotes-active-title { margin-bottom: 35px }
    .quotes-active-title h3.tab-title { font-size: 18px; line-height: normal; /*margin-bottom: 10px*/ }
    .quotes-active-inner .status-active { font-size: 11px; font-weight: 600 }
    .quotes-active-inner .quotes-active-right .day-btn { font-size: 20px; width: 100% }
    .quotes-active-inner .quotes-active-right .day-btn .exp-days { font-size: 12px }
    .quotes-active-inner .quotes-active-right p { order: 2; font-size: 10px; padding-right: 0 }
    .quote-expired-message { text-align: center; flex-direction: column }
    .quote-expired-left { width: 100% }
    .quote-expired-right { margin-top: 15px }
    .quote-expired-message h3 { font-size: 21px; margin-bottom: 10px }
    .request-delievry-quotes .orange-bg-btn2 { font-size: 11px }
    .added-item-list>ul>li { padding-top: 25px; padding-bottom: 25px }
    .active-quote-added-product .item-title-price, .active-quote-added-product .item-options-edit-container { flex-direction: column }
    .active-quote-added-product .item-price { text-align: left; margin-top: 16px }
    .active-quote-added-product .item-price span:not(.ex-gst) { font-size: 15px }
    .active-quote-added-product .item-other-options { margin-top: 20px }
    .active-quote-added-product .lead-time h3 { margin-top: 20px }
    .quotes-active-inner .company-info-wrapper h3.tab-title { font-size: 18px; margin-bottom: 5px }
    .quotes-active-inner .company-info-wrapper p { font-size: 12px; line-height: 17px }
    .quotes-active-inner .company-info-wrapper .company-form { padding-left: 0; padding-right: 0 }
    .quotes-active-inner .company-info-wrapper .company-form .col { flex: 0 0 100% }
    .active-quote-added-product .item-options-edit-container .lead-time .edit-btn { position: absolute; right: 0; top: -10px; background: #eee; padding: 8px 9px; width: 32px; height: 32px; border-radius: 20px }
    .company-form .common-label { line-height: 18px; margin-bottom: 8px }
    .zone-info-main { padding-left: 15px; padding-right: 15px; margin-bottom: 20px }
    .quotes-active-inner .active-quote-price-container .price-left label { font-size: 13px }
    .quotes-active-inner .active-quote-price-container .price-right .price { font-size: 15px }
    .quotes-active-inner .active-quote-price-container .price-label { font-size: 10px }
    .active-quote-price-container .active-price-item, .active-quote-price-container .total-price .active-price-item { padding-left: 8px; padding-right: 8px }
    .deposit-payment-warning .active-price-item .deposit-info { font-size: 16px }
    .new-credir-earn-left { padding: 17px 20px 14px 20px }
    .new-credir-earn-left h3 { font-size: 17px; font-weight: normal; margin-bottom: 10px; font-family: "Lexend Deca", sans-serif }
    .new-credir-earn-wrap p { font-size: 13px }
    /*.new-credir-earn-right ul li { flex-direction: column }*/
    .new-credir-earn-right h4 { font-size: 13px; font-weight: 600; margin-bottom: 15px; color: #707070 }
    .new-credir-earn-right p { padding-left: 10px; font-size: 18px; font-family: "Lexend Deca", sans-serif }
    .new-credir-earn-bottom { flex-direction: column }
    .new-credir-earn-bottom-left { flex-direction: column; margin-bottom: 35px }
    .new-credir-earn-bottom .leadtime { width: 100%; margin-left: 0; order: 2; margin-bottom: 10px }
    .new-credir-earn-bottom .leadtime.delievry-leadtime { order: 1 }
    .new-credir-earn-bottom-right { text-align: center }
    .new-credir-earn-bottom-right a { justify-content: center }
    .fixedscroll-section .faq-section { padding-left: 20px; padding-right: 20px }
    /*.gather-commitment-credit-wrapper .price-boxes { flex-direction: column }*/
    /*.gather-commitment-credit-wrapper .credit-box, .gather-commitment-credit-wrapper .final-total-box { flex: 0 0 100%; max-width: 100% }*/
    /*.gather-commitment-credit-wrapper .credit-box { margin-bottom: 20px; display: flex; flex-direction: column; padding-top: 10px; padding-bottom: 10px }*/
    /*.gather-commitment-credit-wrapper .credit-box .price-meter-box { margin: 10px 0 }*/
    .gather-commitment-credit-wrapper .final-total-box { padding-top: 10px; padding-bottom: 10px }
    .quotes-tab-container { padding-left: 0; padding-right: 0 }
    .quote-tab-content h4 { font-size: 14px }
    .quote-tab-content p { font-size: 13px; line-height: 20px }
    .quotes-list .order-stat-table-title { text-align: left }
    #gather-commitment .tab-title-main { font-size: 25px }
    #gather-commitment .quote-tab-content p { font-size: 16px; line-height: 20px }
    #gather-commitment .gather-earn-how-it-worls h3 { font-size: 15px }
    .gather-earn-value-process { padding-top: 30px; padding-left: 20px; padding-right: 20px }
    .gather-earn-container { text-align: center; flex-direction: column }
    .gather-earn-left h3 { font-size: 30px; margin-bottom: 24px }
    .earn-value .earn-value-title { font-size: 14px }
    .earn-value .earn-value-title span { font-size: 24px; vertical-align: bottom }
    .gather-commitment-card { flex-direction: column }
    .gather-card { width: 100%; margin-bottom: 20px }
    .use-reward-note p { font-size: 14px }
    #gatherCoCredit .gatherco-credit-container { flex-direction: column }
    #gatherCoCredit .gatherco-credit-container>div { width: 100% }
    .gatherco-modal-content { padding: 20px 30px }
    #gatherCoCredit .gatherco-credit-container .gatherco-active-quote-list .nav-tabs { margin-right: 0; padding: 20px; max-height: none }
    /*.gatherco-credit-modal-right { display: none; right: -100%; padding-left: 20px; padding-right: 20px; transition: all 2s ease-in-out; position: fixed; background: #fff; z-index: 999; height: calc(100vh - 60px); overflow: auto }*/
    /*.modal-content-opened .gatherco-credit-modal-right { right: 0 }*/
    /*.modal-content-opened .gatherco-credit-modal-right, #gatherCoCredit .modal-content-opened .back-to-main { display: block }*/
    #gatherCoCredit .back-to-main { display: none }
    /*.default-quote-placeholder { min-height: 100px }*/
    /*.grey-box-price .price-boxes { flex-direction: column }*/
    /*.grey-box-price .final-total-box { padding-bottom: 15px; padding-top: 15px; max-width: none; margin-top: 20px }*/
    .two-shade-right .two-shade-content { padding: 80px 21px }
}
@media screen and (max-width:1024px) {
    .home-gatherco-credit-wrap .earn-value-content p { margin-left: 0 }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .two-shade-right.bg-transparent { display: none }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .two-shade-left { width: 100%; text-align: center }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .earn-value-content p { margin-left: auto; margin-right: auto }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .two-shade-left .plant-image { margin-bottom: 35px }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .two-shade-left .two-shade-content { padding: 60px 25px }
    .product-slider-navigation { left: 70.5vw !important;width: 100%;}
    .format-sizing .surface-sizing-tbl-wrap table tr td { width: 170px; max-width: 170px; padding-right: 15px; text-align: left }
    .format-sizing .surface-sizing-tbl-wrap table tr td:last-child { padding-right: 0 }
    .format-sizing .surface-sizing-tbl-wrap table tr td.active-col~td { display: block }
    .format-sizing .surface-sizing-tbl-wrap table tr td.width-30 .signin-trigger { min-width: 64px; padding: 2px 5px !important }
    .format-sizing .surface-sizing-tbl-wrap table tr td:empty { display: none !important }
    .format-sizing .surface-sizing-tbl-wrap table .active-col tr .td_rowspan { padding-bottom: 10px }
    .format-sizing .surface-sizing-tbl-wrap table .active-col td { display: block }
    .format-sizing table tbody.active-col td { display: block }
    .product-slider-navigation { left: 50vw!important }
}
@media screen and (max-width:991px) {
    .trade-deshboard-title span { margin-left: 13px !important }
    .image-display { display: none !important }
    .image-display-top { display: block }
    .mb-20 { margin-bottom: 20px }
    .product-mobile { display: flex }
    .product-mobile .product-detail-title { width: 100% }
    .product-mobile .dropdown span { font-size: 18px }
    .product-mobile .dropdown button { padding: 0px }
    .product-mobile .dropdown .dropdown-menu { min-width: 182px; border: none; border-radius: 10px; overflow: hidden; margin-top: 10px }
    .product-mobile h5 { font-size: 13px; line-height: 24px; letter-spacing: 0px; color: #222324; opacity: 1; font-family: "Montserrat"; font-weight: 600 }
    .product-mobile .dropdown-menu a { font-size: 14px; font-weight: 600; display: block; padding: 8px 20px }
    .boards-dispaly { display: flex }
    .boards-dispaly a { width: 100% }
    .product-mobile .dropdown-menu { margin-left: -150px }
    .boards-desktop, .favorite-desktop { display: none }
    .profile-left { max-width: 100%; flex-basis: 100%; margin-bottom: 50px }
    .right-content { width: calc(100% - 200px) }
    .photo-upload-modal .profile-photo-placeholder { width: 200px; height: 200px }
    .sidebar-usermenu .dropdown-menu { width: 185px }
    .product-options .dropdown-menu { margin-left: -150px }
    .logo.mobile-logo img { max-height: 28px !important; width: auto !important; min-width: auto !important; max-width: 100% !important }
    .sample-table-container { overflow: auto }
    .surfaces-main-container { padding-top: 50px; padding-left: 30px; padding-right: 30px; padding-bottom: 50px }
    .product-container-main { margin-right: -30px; margin-left: -30px }
    .product-list-carousel { padding-left: 30px; padding-right: 30px }
    .two-shade-section.gatherco-credit-section { flex-wrap: wrap }
    .two-shade-left { width: 100% }
    .two-shade-right { width: 100% }
    .contact-type-list .project-detail-title {margin-bottom: 30px !important; font-size: 35px; line-height: 55px;}
    .smiles-video .video-container { height: 450px }
    .plant-image { margin-bottom: 30px }
    .earn-value-content p { margin: 0 auto; margin-bottom: 40px }
    .home-gatherco-credit-wrap .earn-value-content p { margin-bottom: 40px }
    .blog-detail-container { padding: 0 30px }
    .two-shade-section.gather-comm-page { flex-wrap: wrap }
    .reward-row+.reward-row { margin-top: 50px; flex-direction: column-reverse }
    .reward-image { margin-top: 30px }
    .page-banner-title { line-height: 60px; font-size: 50px }
    .page-banner-title span { line-height: 60px; font-size: 50px }
    .project-detail-right { max-width: 100%; float: right }
    .project-gallery .image-box { width: 100% !important; height: auto !important; left: 0 !important; top: 0 !important; position: relative; margin-bottom: 20px }
    .project-gallery .image-box img { width: 100% !important; height: auto !important; margin: 0 !important; position: relative !important; top: 0; left: 0 }
    .project-gallery .justified-gallery { height: auto !important }
    .know-more.scroll-section-widget { border-bottom: none; padding-bottom: 0; margin-bottom: 10px }
    .mobile-panel-active .icon-up-caret { font-size: 18px }
    .mobile-panel-active { display: block }
    .mobile-panel-close { display: inline-block }
    /*.header-right .add-to-favourite { display: none }*/
    .mobile-panel-price h4 { font-size: 13px; font-weight: 500 }
    .mobile-panel-price h4 small { font-size: 9px }
    .mobile-panel-price label { margin: 0; font-size: 12px; font-weight: 600 }
    .mobile-panel-price label small { font-size: 9px; font-weight: 600 }
    .mobile-panel-close span { color: #aca7a7; font-size: 14px }
    .my-order-panel.active-panel { width: 100%; right: 0 }
    .mobile-panel-active { position: absolute; top: 10px; width: 100%; text-align: center; left: 0 }
    .texture-guide-modal-container { flex-wrap: wrap }
    .texture-guide-left { width: 100% }
    .texture-guide-right { width: 100% }
    .rating-detail ul { max-height: 100%; height: auto }
    .texture-rating-wise-detail { width: calc(100% - 119px) }
    .available-stock-right { display: none }
    .available-stock-left { width: 100% }
    .available-product-detail { margin: 0; max-height: 100% }
    .available-stock-left .tab-pane { display: block !important; opacity: 1 }
    .available-product-detail-body { border-bottom: 1px solid #efefef; margin-bottom: 30px; padding-bottom: 30px }
    .availalable-product-gallery .product-gallery-image img { height: calc(100vh - 170px); object-fit: cover; object-position: center }
    .signup-section { min-height: 430px }
    .gather-commitment-card { margin-bottom: 0 }
    .fixedscroll-section .right-content .tab-content .faq-section { margin-bottom: 0; padding-top: 50px; padding-bottom: 50px }
    .fixedscroll-section .right-content .tab-content .faq-section .section-inner-container { padding: 0 }
    .fixedscroll-section .right-content .tab-content .faq-section .section-inner-container .col-xl-4.col-lg-4.col-md-4.col-sm-12.col-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100% }
    .accumulated-credit, .gather-commitment-card { margin-top: 40px }
    #quotes .tab-inner-container { padding-bottom: 40px }
    .feature-product-grid .image-grid { padding: 0 20px }
    .board-product-name .product-detail-options { margin-left: 0 }
    .contact-form-section+.faq-section .faq-article { margin-top: 50px }
    .make-us-different, .two-shade-right { min-height: inherit }
    .home-gatherco-credit-wrap .two-shade-right { min-height: inherit }
    .pricing-page-main-table .pricing-table-widget .format-sizing-table table td { padding: 10px 10px !important; border-bottom: 1px solid #e8ebed; height: auto }
    .surface-accordion .resources-list .resources .col-3 { flex: 0 0 25% !important; max-width: 25% !important; }
    .resources-list .resources .col-3 { margin-bottom: 0 !important; }
    .seamless-texture-image .seamless-images ul li { width: 25% !important; }
    .seamless-images { padding: 15px 22px; }
    .product-group-carousel { padding: 0 10px; padding-bottom: 15px !important; }
    .faq-section{padding: 20px 25px!important;}
    .faq-article{margin-top: 20px!important;}
    .need-more-help{margin-top: 20px!important;margin-bottom: 20px!important;}
    .reward-process-section .section-inner-container{padding: 0 25px!important;}
    .service-list-section .swiper-container-autoheight, .service-list-section .swiper-container-autoheight .swiper-slide{height: 100%!important;}
    .service-list-section .swiper-container-autoheight img, .service-list-section .swiper-container-autoheight .swiper-slide img{height: 100%!important;}
    .earn-value-content .grey-bordered-btn {margin: 0 auto}
}
@media screen and (max-width:767px) {
    .order-table-header-right .date-display-none { display: none }
    .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate { text-align: left !important }
    .trade-quote-footer .footer-left { margin-bottom: 0px }
    .order-table-action { margin-left: 10px }
    .go-commit-left-text .text-left-get { text-align: left; margin-left: 0 !important }
    .go-commit-left-text .home-sign-up-btn { float: left }
    .logo { display: none }
    .logo.mobile-logo { display: inline-block !important; width: 20px !important }
    .powered-by { text-align: center; margin-bottom: 20px }
    .explore-box h3 { font-size: 40px !important; line-height: 60px !important }
    .explore-box h4 { font-size: 16px; margin-bottom: 5px; text-transform: uppercase }
    .profil-page-main { margin-top: 30px; margin-bottom: 30px }
    /*.left-sidebar .nav { margin-top: 10px }*/
    .surface-tab-section .surfaces-tab-filter .nav-tabs { justify-content: space-between }
    .filter-product-grid .selected-filters { flex-wrap: nowrap }
    .filter-product-grid .selected-filter-list { margin: 0 }
    .page-banner-section { min-height: 350px; margin-top: 0; background-position: center }
    .section-inner-container { padding: 0 20px }
    .resource-item-detail { margin-top: 13px }
    .earn-value-content .plant-image { margin-bottom: 15px }
    .earn-value-content .plant-image img { max-width: 145px }
    .filter-product-grid .product-grop-grid>ul>li { width: 50% }
    .surface-explore { background-image: url("https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING/gatherco_surfaces.png?tr=w-952,h-283");width: auto;height: auto; }
    .decore-explore { background-image: url("https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING/GatherCo_decor_images.png?tr=w-952,h-283");width: auto;height: auto; }
    .product-option-left { width: 100%; margin-bottom: 20px }
    .product-rating-items { justify-content: center }
    .resources-list ul li { width: 100%; padding: 10px 0 }
    .product-option-right .specification-name { font-size: 11px }
    /*.product-list-navigation { display: none }*/
    .product-list-pagination { position: absolute; bottom: 15px !important; z-index: 1; left: 10px !important; width: auto !important }
    .product-list-pagination .swiper-pagination-bullet { border: 1px solid #fff; opacity: 1; width: 10px; background: transparent; height: 10px; outline: none }
    .product-list-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active { background: #fff }
    .favorite-product { position: absolute; right: 17px; top: 21px; z-index: 9; display: block; color: #fff; font-size: 18px }
    .installation-products .item-name h4 { font-weight: 700 }
    .install-item { border: none }
    .recommended-options.scroll-section-widget { padding-bottom: 15px }
    .color-options { margin-bottom: 15px }
    .large-modal .modal-dialog.modal-dialog-centered { width: 100%; margin: 0 auto; min-height: 100vh; align-items: flex-start }
    .large-modal .modal-dialog.modal-dialog-centered .modal-content { border-radius: 0; min-height: 100vh }
    .grout-color-widget .color-options li { width: calc(50% - 40px); min-width: auto }
    .grout-color-container { max-height: 100% }
    #moreInformationModal .modal-header .close, #groutColor .modal-header .close { position: relative; top: 0; right: 10px }
    #moreInformationModal .modal-header .title-lexend-26, #groutColor .modal-header .title-lexend-26 { font-size: 18px; font-weight: 700 }
    #moreInformationModal .modal-header, #groutColor .modal-header { margin: 0; align-items: center; border-bottom: 1px solid #dee2e6 }
    .recommended-body .color-options ul li { width: 50% }
    .texture-guide-left .title-lexend-26 { display: none }
    .textureGuideModal .modal-header, .slipRating .modal-header, .finishesModal .modal-header, .ratingGuideModal .modal-header, #gatherCoCredit .modal-header { position: relative; border-bottom: 1px solid #dee2e6; padding: 20px 20px }
    .textureGuideModal .modal-header .title-lexend-26, .slipRating .modal-header .title-lexend-26, .finishesModal .modal-header .title-lexend-26, .ratingGuideModal .modal-header .title-lexend-26, #gatherCoCredit .modal-header .title-lexend-26 { font-size: 18px; font-weight: 700 }
    .image-box-inner img { border-radius: 5px }
    .product-enlarge-gallery-container { max-width: 100% }
    .imageEnlarge { background: #000 }
    .image-enlarge-pagination { position: absolute; bottom: 15px; left: 15px; z-index: 11 }
    .image-enlarge-pagination .swiper-pagination-bullet { width: 10px; height: 10px; border: 1px solid #fff; opacity: 1; background: transparent; margin-right: 8px; outline: none }
    .image-enlarge-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active { background: #fff }
    .imageEnlarge .modal-dialog { margin: 0 }
    .enlarge-close { position: absolute; left: 15px; top: 15px; z-index: 11 }
    .enlarge-close .close { color: #fff; text-shadow: none; opacity: 1; font-size: 15px }
    .product-enlarge-gallery .product-gallery-image img { height: auto; max-height: 370px; width: 100%; object-fit: cover; object-position: center }
    .imageEnlarge .modal-dialog .modal-content { min-height: 100vh; display: flex; align-items: center; justify-content: center; padding: 50px 0 }
    .enlarge-image-options { right: 15px; top: 15px }
    .view-avialable-stock .title-lexend-25 { font-size: 18px }
    .view-avialable-stock.grey-bordered-btn { width: 92px }
    .pricing-page .surface-tab-section .surfaces-tab-filter .nav-tabs { justify-content: flex-start }
    .imagery-header-right .download-tranparent { display: none }
    .imagery-header { padding: 20px 20px }
    .available-product-detail-body { flex-wrap: wrap; flex-direction: column-reverse }
    .availalable-product-gallery { width: 100% }
    .available-product-detail ul { display: flex; flex-wrap: wrap }
    .available-product-detail ul li { padding-right: 30px }
    .available-product-detail-widget { margin: 15px 0 }
    .available-stock-left { padding: 15px 0 }
    .available-product-detail-header { padding: 0 20px }
    .available-product-detail { padding: 10px 20px 0 20px; width: 100% }
    .textureGuideModal .modal-body, .slipRating .modal-body, .finishesModal .modal-body, .ratingGuideModal .modal-body, #gatherCoCredit .modal-body { height: calc(100vh - 61px); overflow-y: auto }
    .donation-widget .btn { font-size: 11px }
    #dashboard .btn-secondary { background-color: #222324; border-color: transparent; color: #fff }
    .smaple-wrapper { position: relative }
    .smaple-wrapper .sample-detail-left a { position: absolute; bottom: 0px; left: 16%; transform: translateX(-50%); border: 2px solid #222324; color: #222324; height: 28px; min-width: 76px; font-size: 11px; border-radius: 21px; line-height: 25px; text-align: center }
    .gather-card .donate-amount-list { margin: 40px auto; max-width: 250px }
    .donate-amount-list ul li { margin: 10px }
    .amount-item label { width: 101px }
    .donation-detail-form .row:first-child { flex-direction: column }
    .info-table-order .table-heading { display: flex; flex-wrap: wrap; border-bottom: 1px solid #ebebeb }
    .info-table-order .table-heading-left h4 { display: none }
    .info-table-order .heading-info { flex-wrap: wrap; padding-top: 15px }
    .info-table-order .heading-info h4 { font-size: 12px; font-weight: 600; padding-left: 19px; margin-bottom: 15px }
    .info-table-order .heading-info h4 span { display: inline-block; height: 26px; width: 26px; line-height: 26px; text-align: center; border-radius: 100%; font-size: 14px; color: #fff; margin-right: 12px }
    .profile-photo-placeholder { width: 150px; height: 150px }
    .checkout-page { padding: 15px !important; width: 100% }
    .checkout-body-container { flex-direction: column }
    .checkout-header, .checkout-left-col, .checkout-right-col { max-width: 100%; width: 100% }
    .checkout-header { justify-content: center; margin-bottom: 15px }
    .header-right { display: none }
    .checkout-logo img { max-height: 28px }
    .checkout-left-col { order: 2 }
    .checkout-right-col { order: 1; display: none }
    .show-orders { display: block; background: #f7f7f7; color: #aab0b1; height: 55px; margin-left: -15px; margin-right: -15px; margin-bottom: 30px; padding: 10px 15px; display: flex; justify-content: center; align-items: center; font-size: 13px; font-weight: 500 }
    .show-orders i { font-size: 20px; margin-right: 15px; color: #aab0b1 }
    .show-orders i.icon-down-arrow { font-size: 9px; margin-right: 0; margin-left: 15px }
    .order-summary-active i.icon-down-arrow { transform: rotate(180deg) }
    .checkout-form-widget>div { flex-direction: column }
    .checkout-breadcrumb .breadcrumb { justify-content: center }
    .checkout-breadcrumb .breadcrumb li { font-size: 11px }
    .checkout-breadcrumb .breadcrumb li:not(:first-child)::before { top: -1px; font-weight: normal; line-height: 14px }
    .checkout-continue-return { flex-direction: column }
    .checkout-continue-return .white-bg-btn { order: 2 }
    .project-reference { display: none }
    .item-title-price { flex-direction: column }
    .checkout-right-col .item-name { margin-bottom: 18px }
    .checkout-right-col .item-price { margin-bottom: 25px }
    .price-left label { font-size: 13px }
    .price-minus-item .price-left label { color: #222324 }
    .price-right .price { font-size: 15px }
    .new-credir-earn-bottom.two-col-leadtime .leadtime.delievry-leadtime { margin-left: 0; margin-bottom: 11px }
    .leadtime { border-radius: 5px; padding-top: 13px; padding-bottom: 13px }
    .leadtime:not(.delievry-leadtime) { display: flex; flex-direction: row; align-items: center; justify-content: center }
    .leadtime:not(.delievry-leadtime) h3 { margin: 0 24px }
    .leadtime:not(.delievry-leadtime) h3 span { margin-bottom: 0 }
    .checkout-footer { flex-direction: column }
    .footer-left { margin-bottom: 40px }
    .profile-pic-modal .modal-dialog { width: calc(100% - 2rem); margin: 0 auto }
    .profile-form .error { margin-top: 0; margin-bottom: 10px; display: inline-block }
    .notifications.top-right { width: 95% }
    .order-complete-modal-container { flex-direction: column }
    .order-complete-modal-left, .order-complete-modal-right { width: 100% }
    .order-complete-modal-right { padding-left: 10px; padding-right: 10px }
    .earn-value-content { text-align: center }
    #videoPreviewModal iframe { height: 380px }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .two-shade-left .two-shade-content { padding: 52px 20px }
    .contact-form-section+.faq-section { padding-top: 50px }
    .contact-form-section+.faq-section .faq-article { margin-top: 40px }
    .register-tab .form-submit-btn { margin-top: 5px }
    .search-form-widget .form-control { height: 44px; padding: 10px 45px 10px 20px; font-size: 16px }
    .search-form-widget .search-widget-btn { font-size: 20px; right: 18px }
    .two-shade-content { padding: 60px 25px }
    .two-shade-right .two-shade-content { padding: 60px 20px }
    .reward-process-section { padding: 60px 0 }
    .gather-commitment-two-shade .two-shade-content { padding-left: 25px }
    .sampleLibrary .modal-body { padding: 20px 10px 0 }
    .search-sample-form { text-align: center; margin-top: 0; margin-bottom: 30px }
    .sampleLibrary .sample-detail-bottom .sample-detail-inner h4 { margin-left: 17px }
    .change-address-modal .quotes-model-inner { padding: 0 20px 25px }
    .quotes-model-inner { padding: 0 20px }
    .tab-inner-container { padding: 25px 20px }
    .featured-product.featured-projects { padding-bottom: 30px }
    .featured-product .feature-product-carousel .navigation-pagination-container .product-slider-arrow { display: none }
    .product-gallery-pagination { bottom: 12px !important; text-align: right; padding: 0 15px }
    .product-gallery-pagination .swiper-pagination-bullet { width: 8px; height: 8px; margin: 0 3px !important; border: 1px solid #fff }
    .product-gallery-main .product-gallery-image img { -webkit-border-radius: 0; border-radius: 0 }
    .product-color-palette { padding-right: 22px; bottom: 8px; left: 8px }
    .product-color-palette ul li { margin-right: 5px; margin-top: 5px }
    .samples-table.sample-tab .sample-detail-inner h4 { margin-left: 20px; word-break: break-word }
    .board-product-name .product-detail-options { margin-left: 0 }
    .tab-inner-container .board-product-name { -webkit-flex-direction: column; flex-direction: column; -webkit-align-items: center; align-items: center; -webkit-flex-wrap: wrap; flex-wrap: wrap }
    .show-all-article .trending-article-list .grey-bordered-btn { margin-top: 0; margin-left: 40px }
    .product-pagination .swiper-pagination-bullet { width: 6px; height: 6px; vertical-align: middle;}
    .earn-value .earn-value-title span { font-size: 30px }
    .featured-projects .feature-section-title { margin-bottom: 35px }
    .surfaces-main-container { background: #fdfdfd }
    .product-gallery-detail { display: none }
    .product-gallery .show-in-mobile { display: block; margin-top: 15px }
    .blog-list-wrap .more-project-btn { text-align: center; padding-left: 0 }
    .detail-product-gallery .product-gallery-pagination { text-align: left }
    .detail-product-gallery .product-gallery-pagination .swiper-pagination-bullet { width: 10px; height: 10px; border: 2px solid #fff }
    .project-list-wrap { overflow: hidden }
    .blog-list-wrap .project-details h4 { font-size: 12px; line-height: 18px }
    .blog-list-wrap .project-details h4 .post-date { font-size: 14px; margin-top: 5px; margin-left: 0; margin-bottom: 5px }
    .blog-list-wrap .project-details .product-category { font-size: 14px }
    .surface-explore, .decore-explore { background-position: center }
    .detail-get-more .get-more-right { display: none }
    .detail-get-more .get-more-left.mb-20 { margin: 0 }
    .pricing-table-widget-header.stickOnScroll-on:before { right: -20px }
    .pricing-table-left { width: 200px }
    .pricing-table-container .pricing-table-right { width: calc(100% - 200px) }
    .left-sidebar .nav .nav-item .nav-link { padding-left: 12px }
    .left-sidebar .nav .nav-item .nav-link>span { margin-right: 10px }
    .phone-qr-section .section-inner-container{padding: 0 25px!important;}
    body .surface-decore-section .surface-and-decore-hover .explore-box h3{font-size: 40px!important; line-height: 60px!important;}
    .product-detail-inner .sport-option{height: 40px; width: 40px;}
    .product-detail-inner .dropdown-menu {width: 40px; min-width: 40px;transform: translateY(10px)!important;}
    .user-profile-Update.popover{max-width: 300px!important;min-width: auto!important;}
    .product-detail-inner .dropdown-menu .sport-menu ul li .favourite-btn{width: 25px;height: 25px;}
    /*.right-content .tab-inner-container {padding: 85px 20px 25px !important;margin-top: 0 !important;}*/
}
@media screen and (max-width:600px) {
    .sample-detail-bottom { margin-top: 10px !important }
    .smaple-wrapper .sample-detail-left a { margin-top: 10px !important }
    .sample-detail-left { display: block !important }
    .zone-info { text-align: left }
    .social-login-icon-button { display: flex }
    .width-95 { width: 95px }
    .width-40 { width: 40px }
    .width-30 { width: 30px !important }
    .width-30 .grey-bg-btn { padding: 0px 5px !important }
    .blog-detail-container .blog-detail-inner .project-detail-right p img { height: auto !important }
    .explore-box { margin-left: -20px; padding-top: 25px }
    .right-content { width: 100% }
    .search-form.active-search input[type="text"] { width: 165px }
    .container-fluid { padding: 0 15px }
    .account-pages { padding-top: 110px }
    .account-pages .tab-container { flex-direction: column }
    .left-sidebar { background: #fff; width: 100%; height: auto; top: 55px; z-index: 99; transition: 0.3s all ease-in-out }
    /*.left-sidebar .nav { display: none; height: 0; opacity: 0; transition: 0.3s all ease-in-out }*/
    .mobile-trigger span { display: inline-flex; align-items: center }
    .mobile-trigger span i { margin-right: 10px; font-size: 16px }
    .mobile-trigger .icon-down-arrow { display: inline-block; transition: 0.3s transform ease-in-out }
    .mobile-trigger.active-menu .icon-down-arrow { transform: rotate(180deg) }
    /*.left-sidebar.active-sidebar .nav { display: block; height: auto; margin-top: 0; opacity: 1 }*/
    .white-bg-btn, .grey-bg-btn { font-size: 12px }
    .tab-inner-container { padding: 25px 20px }
    .navbar-toggler { margin-left: 5px }
    .btn-row .btn { width: 100%; margin-bottom: 15px }
    .profile-photo-block .btn-row .btn { max-width: 109px; height: 39px }
    .btn-row>.col { width: 100%; flex-basis: 100% }
    .profile-form .row>.col { width: 100%; flex-basis: 100% }
    .profile-form .row.btn-row>.col { width: 50%; flex-basis: 50% }
    .profile-form .common-label { font-size: 12px; margin-bottom: 7px }
    .profile-form .btn-row .grey-bordered-btn { color: #fff; background-color: #343a40; border-color: #343a40 }
    .profile-form .btn-row .transparent-bg-btn { text-align: center; font-size: 14px; font-weight: 600; padding: 7px 10px; color: #222324; border: 2px solid #222324 }
    .company-information-form form .row>.col { width: 100%; flex-basis: 100% }
    .favourite-product-list>ul>li { width: 100%; max-width: 100% }
    .favourite-product-list { padding: 0 15px }
    .favourite-tabs .nav li.nav-item a { padding: 0 15px; font-size: 12px }
    .sample-detail-left h4 { font-size: 14px }
    .sample-detail-inner img { width: 55px; height: 55px }
    .sample-detail-inner h4 { font-size: 12px }
    .sample-detail-bottom ul li { padding: 0 15px }
    .table-heading { padding: 15px }
    .sample-detail-wrapper { padding: 15px 12px 0px 15px }
    .favourite-slide-inner-wrapper { padding: 15px; padding-bottom: 44px }
    .faq-section>h3 { margin-bottom: 15px }
    .fixedscroll-section .faq-box p { font-size: 16px }
    .sample-search input { width: 100% }
    .sampleLibrary .modal-dialog.modal-dialog-centered { max-width: 100% }
    .cart-panel { width: 100%; z-index: 999; height: 100%; top: 0 }
    .cart-body { padding: 15px }
    .cart-total { padding: 20px 15px }
    .user-avatar-name span { font-size: 20px }
    .user-avatar-img { width: 67px; height: 67px }
    .user-avatar { padding: 20px }
    .user-detail-table { display: none }
    .page-banner-title { line-height: 46px; font-size: 40px }
    .page-banner-title span { line-height: 46px; font-size: 40px }
    .two-shade-content { padding: 40px 20px !important; width: 100% }
    .two-shade-section { flex-wrap: wrap }
    .two-shade-left, .two-shade-right { width: 100% }
    .earn-value-content label { font-size: 30px; line-height: 34px; margin-bottom: 20px }
    .home-gatherco-credit-wrap .earn-value-content label { line-height: 36px; margin-bottom: 20px }
    .earn-value-content p { margin-bottom: 25px; font-size: 16px; line-height: 25px }
    .home-gatherco-credit-wrap .earn-value-content p { margin-bottom: 20px }
    .gather-credit-title { font-size: 30px; line-height: 36px }
    .plant-image img { max-width: 130px }
    .earn-value .earn-value-title { margin-bottom: 20px; line-height: 38px }
    .signup-inner-container .sign-up-title { font-size: 30px; margin-bottom: 25px; line-height: 36px; padding: 0 25px }
    .signup-inner-container p { font-size: 16px }
    .signup-section { padding: 60px 20px; min-height: inherit }
    .signup-inner-container p br { display: none }
    .service-list { padding: 50px 20px }
    main { padding-top: 55px }
    .featured-product { padding: 30px 0 50px }
    .make-us-left .section-title { font-size: 40px; line-height: 45px }
    .make-us-left .section-description { margin-bottom: 50px }
    .surfaces-main-container { padding-top: 30px; padding-left: 20px; padding-right: 20px; padding-bottom: 30px }
    .surface-title { font-size: 30px }
    .surfaces-tab-filter .nav-tabs { justify-content: flex-start; width: 100% }
    .surfaces-tab-filter .nav-tabs .nav-item a { padding-right: 10px; padding-left: 10px }
    .product-label { width: 30px; right: -10px }
    .product-container-main { margin-right: -20px; margin-left: -20px }
    .product-list-carousel { padding-left: 20px; padding-right: 20px }
    .product-detail-left h4 { font-size: 12px; line-height: 15px; margin-bottom: 2px }
    .favourite-btn { font-size: 18px }
    .product-list-type { font-size: 20px; line-height: 26px; margin-bottom: 0 }
    .product-item { margin: 15px 0 }
    .panel-body { padding: 20px }
    .signin-register-panel.active-panel { right: 0; width: 320px }
    .form-label { font-size: 13px }
    .panel-tab-section .form-control { font-size: 13px }
    .register-tab .nav-tabs a { font-size: 13px }
    .back-to-top { margin: 0 }
    .back-to-top .back-btn { width: 148px; height: 44px; font-size: 14px; line-height: 28px }
    .back-to-top .back-btn .icon-right-caret { font-size: 20px }
    #forgot_password_form .singin-social-btn .btn { margin-bottom: 15px }
    .selected-filters h4 { font-size: 20px }
    .selected-filter-list { width: 100%; margin-top: 10px }
    .selected-filters { flex-wrap: wrap }
    .selected-filter-list ul li { margin-bottom: 3px }
    .product-list-carousel .swiper-slide:hover, .filter-product-grid .product-grop-grid>ul>li:hover { transform: none }
    .filter-product-grid .product-grop-grid>ul>li.prev_hover_parent { margin: 0 !important }
    .filter-product-grid .product-grop-grid>ul>li.next_hover_parent { margin-left: 0; margin-right: 0 !important }
    .product-list-group .swiper-slide.prev_hover_parent { margin-left: 0; margin-right: 16px !important }
    .product-list-group .swiper-slide.next_hover_parent { margin-left: 0; margin-right: 16px !important }
    .show-all-article .trending-article-list .grey-bordered-btn { width: 138px; height: 40px; font-size: 13px }
    .product-list-next { right: 30px }
    .pricing-table-container { flex-wrap: wrap; padding: 30px 0px 0 0 }
    .pricing-table-container .pricing-table-right { width: 100% }
    .pricing-table-left { width: 100% }
    .page-banner-container { padding: 0 20px }
    .pricing-page-banner .page-banner-title { font-size: 30px; line-height: 30px; margin-top: 30px }
    .pricing-page-banner .page-banner-title span { font-size: 30px; line-height: 30px; margin-top: 30px }
    .pricing-banner-text { font-size: 14px }
    .left-product-list { overflow: hidden; width: 100%; position: relative !important; top: 0 !important; margin-bottom: 30px }
    .pricing-table-widget-header .product-image img { width: 50px; height: 50px; min-height: auto }
    .left-product-list ul { display: flex; margin-right: 0 }
    .left-product-list ul li .product-item { margin-bottom: 0 }
    .phone-qr-right { margin-top: 30px }
    .faq-section { padding: 50px 0px 50px 0px }
    .process-item { min-width: 150px; text-align: center }
    .item-image { margin: 0 auto }
    .project-detail-left .project-details .product-category { font-size: 14px }
    .featured-product-list { display: flex; flex-wrap: wrap }
    .feature-product { margin-bottom: 20px }
    .project-gallery .image-box { width: 100% !important; height: auto !important; left: 0 !important; top: 0 !important; position: relative; margin-bottom: 20px }
    .project-gallery .image-box img { width: 100% !important; height: auto !important; margin: 0 !important; position: relative !important; top: 0; left: 0 }
    .project-gallery .justified-gallery { height: auto !important }
    .project-details h4 .post-date { display: block; font-size: 14px; margin-top: 5px; margin-left: 0; margin-bottom: -5px }
    .blog-tab .product-category { font-size: 14px; line-height: 18px }
    .blog-tab-section .project-filters .nav-tabs .nav-item .nav-link { white-space: pre }
    .blog-tab-section .project-filters .nav-tabs { flex-wrap: nowrap; overflow: auto; margin-right: -20px }
    .blog-tab .projects-filter-content .row .col-xl-4:nth-child(odd) { padding-right: 5px }
    .blog-tab .projects-filter-content .row .col-xl-4:nth-child(even) { padding-left: 5px }
    .blog-detail-container { padding: 0 20px }
    .blog-inner-image .row .col-6 { flex: 0 0 100%; max-width: 100% }
    .page-banner-section .smiles-logo img { width: 155px }
    .smiles-video .video-container { height: 400px }
    .smiles-video .video-container iframe { position: absolute; width: 120%; height: 125%; top: -15%; pointer-events: none; left: -10% }
    .reward-earn-widget h3 { font-size: 30px; margin-bottom: 20px; line-height: 34px }
    .reward-row+.reward-row { margin-top: 50px; flex-direction: column-reverse }
    .reward-image { margin-top: 30px }
    .gather-comm-page .earn-value-content { text-align: left }
    .gather-comm-page .earn-value-content label br { display: none }
    .reward-process-section { background: #f5efea; padding: 50px 0 }
    .search-form-widget { min-width: 320px!important;}
    .article-faq-container .faq-list { margin-top: 50px }
    .board-project-list ul { max-height: 200px; margin-right: 0 }
    .filter-product-grid .product-grop-grid>ul { margin: 0 -15px }
    .filter-product-grid .product-grop-grid>ul>li { width: 50%; padding: 0 15px }
    .texture-guide-modal-container { flex-wrap: wrap }
    .texture-guide-left { width: 100%; padding: 20px }
    .texture-guide-right { width: 100% }
    .rating-detail ul { max-height: 100%; height: auto; margin-bottom: 30px }
    .slip-rating-container { max-height: 100% }
    .size-meter input[type="number"], .size-meter input[type="text"] { width: 100%; height: 100% }
    .size-meter { width: 65px }
    span.gst { font-size: 9px }
    .product-options-scrollable .product-option-righ.user-avatar-namet .specification-list ul li { width: 25% }
    .image-box-inner { margin-bottom: 5px }
    .texture-rating-container span { width: 41px; height: 41px; line-height: 41px }
    .texture-rating-wise-detail { width: calc(100% - 119px) }
    .texture-guide-right .rating-detail ul li { -webkit-align-items: center; align-items: center; -webkit-justify-content: space-between; justify-content: space-between; padding: 15px 20px; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex }
    .star-rating-container { text-align: left }
    .texture-guide-right .guide-header { padding: 20px }
    .texture-guide-right .guide-header label { text-align: left }
    .show-more-details ul li { width: 100% }
    .quotes-model .modal-body h2 { font-size: 20px; border-bottom: 1px solid #e8ebed; padding-bottom: 12px }
    .quotes-model .modal-body .quotes-model-inner p { font-size: 14px; line-height: 18px }
    .dashbaord-widgets, .quotes-tab-container { margin-top: 63px }
    .favourite-slide-inner-wrapper .slick-list.draggable .slick-track { margin: 0 0 !important }
    .favourite-slide-inner-wrapper .slick-list.draggable .slick-track .slick-slide { padding: 0 0 !important }
    .quote-list-inner { margin: 0 auto }
    .mobile-trigger { display: flex !important; background: #fff; width: 100%; height: 55px; line-height: 54px; font-size: 13px; font-weight: 500; padding: 5px 24px; color: #222324; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04) }
    .quote-card { margin-bottom: 20px }
    .page-breadcrumb { margin-bottom: 16px !important; margin-top: 60px }
    .page-breadcrumb .breadcrumb li a {font-size: 13px !important;}
    #quotes .tab-inner-container { padding-bottom: 40px; margin-top: 75px }
    .page-breadcrumb .breadcrumb { justify-content: flex-start;}
    .tab-title-gathercommitment { margin-top: 60px !important }
    .sample-detail-bottom.in-two-col ul li { flex: 0 0 100%; max-width: 100% }
    .phone-qr-section .section-inner-container{padding: 0 15px!important;}
    .faq-section{padding: 15px!important;}
    .right-content {width: calc(100% - 0px);}
    .reward-process-section .section-inner-container{padding: 0 15px!important;}
    .user-profile-Update.popover{max-width: 300px!important;min-width: auto!important;}
    .product-detail-inner .dropdown-menu .sport-menu ul li .favourite-btn .icon-heart.active:before{background-size: 15px auto!important;}
    .product-detail-inner .dropdown-menu .sport-menu ul li .favourite-btn .icon-play:before{width: 18px!important;height: 18px!important;}
}
@media screen and (max-width:480px) {
    .sampleLibrary .sample-detail-bottom li { -webkit-box-flex: 0; -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100% }
    .samples-table.sample-tab .sample-detail-bottom ul li { -webkit-box-flex: 0; -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100% }
    .add-item-option-list .lead-time-label { font-size: 11px }
    .faq-section .faq-widget-title{margin-bottom: 15px!important;}
    .faq-section .faq-article{margin-top: 0!important;}
    .need-more-help{margin-top: 15px!important;margin-bottom: 15px!important;}
    figure.image img {margin: 0 0 0 0;}
}
@media screen and (max-width:350px) {
    .signin-register-panel { width: 320px !important }
    .search-form-widget { min-width: inherit }
    .format-sizing .surface-sizing-tbl-wrap table tr td { width: 130px; max-width: 130px }
    .product-pagination .swiper-pagination-bullet{margin: 0 2px;}
}
@media screen and (min-width:768px) and (max-width:1024px) {
    .info-table .table-heading .table-heading-left h4 span { display: block }
    /*.table-heading h4 { padding-left: 27px }*/
}
@media screen and (min-width:800px) and (max-width:1024px) {
    .resources-list ul li { width: 100% }
    .know-more.scroll-section-widget { border-bottom: none; padding-bottom: 0; margin-bottom: 10px }
}
@media screen and (min-width:768px) and (max-width:992px) {
    .product-gallery-detail { max-width: 100%; margin: 0 auto; margin-top: 50px }
    .product-label { width: 30px }
    .filter-product-grid .product-grop-grid>ul>li { width: 33.33% }
}
@media screen and (min-width:601px) and (max-width:767px) {
    .service-list { padding-top: 50px; padding-bottom: 50px }
    .section-description { max-width: 100% }
}
@media screen and (min-width:320px) and (max-width:767px) {
    span.fa.fa-info { margin-left: 15px }
    .minimumAmount .modal-dialog { transform: none; max-width: 100%; width: 100%; margin: 0 }
    .minimumAmount .modal-dialog .modal-content { border: none !important; box-shadow: none !important; border-radius: 0 !important; height: 100vh !important }
    .item-other-details .lead-time h3 span:empty { display: inline-block !important; margin-left: 5px }
    .favourite-modal .modal-dialog { margin: 0 auto !important }
    .navbar-collapse { position: fixed; top: 0; width: 100%; height: 100vh !important; z-index: 99991; max-height: 100%; background: #151515; border-radius: 0; float: left; margin: 0; padding: 0 }
    .text-center { text-align: center !important; margin-bottom: 26px }
    .recent-product .product-list-group .product-container-main .slick-list .slick-track .slick-slide { padding: 0 7px !important }
    span.table-dimensions-details { overflow: hidden; text-overflow: ellipsis; white-space: nowrap }
    .page-banner-section.blog-expanded-banner { height: auto !important; min-height: auto !important; padding-bottom: 50%; background-position: inherit !important }
    .groutColor .grout-colour-range .modal-header { justify-content: flex-start }
    .groutColor .grout-colour-range .modal-header .title-lexend-26 { font-size: 14px }
    .groutColor .grout-colour-range .modal-header .close { top: 28px }
    .filter-product-list .product-list-group .product-container-main .product-list-carousel .swiper-wrapper .swiper-slide.scaling ~ .swiper-slide { transform: translate3d(4vw, 0, 0) }
    .product-item-detail .product-detail-right .favourite-btn .icon-play:before { width: 16px; height: 19px; background-size: 16px auto }
    .product-item-detail .product-detail-right .favourite-btn .icon-heart:before { background-size: 16px auto; width: 16px; height: 16px }
    .product-item-detail .product-detail-right .favourite-btn { width: 26px; height: 26px }
    .product-item-detail .product-detail-right .favourite-btn .icon-heart.active:before { background-size: 16px auto }
    .right-sidebar .add-to-favourite span.icon-heart, .product-item-detail .product-detail-right .favourite-btn span { padding: 2px 0 0 0 }
    .product-gallery-main .product-gallery-image { max-height: 100%; border-radius: 4px; text-align: left }
    .product-gallery-main .product-gallery-image img { width: 100%; height: 100%; max-height: 100%; max-width: 100% }
    .surface-tab-section .serface-tab-filter-content .product-gallery .align-items-center .product-gallery-images { padding: 0 0; width: 100% }
    .color-palette-thumb { width: auto; height: auto; border: 0 }
    .product-color-palette ul li .color-palette-thumb a { width: 24px !important; height: 24px !important }
    .product-color-palette ul li .color-palette-thumb .color-palette-name { font-size: 12px; height: 24px; line-height: 24px; }
    .product-item .product-item-image .product-color-palette { bottom: 15px }
    .product-item-detail .product-detail-right .favourite-btn:nth-child(1) { display: none !important }
    .signin-register-panel.active-panel { right: 0; width: 100% }
    .social-login-icon-button { flex-wrap: wrap }
    .signin-register-panel form .col-6 { width: 100%; max-width: 100%; flex: 0 0 100% }
    .register-form-field-grp .col { width: 100%; max-width: 100%; flex: 0 0 100% }
    .signin-register-panel .panel-header .icon-close { top: 38px }
    .form-submit-btn .btn { padding: 7px 0; font-size: 12px }
    .signin-register-panel { width: 100%; right: -100% }
    .home-gatherco-credit-wrap .earn-value-content p { text-align: left; font-size: 16px; line-height: 24px; margin-bottom: 20px !important }
    .home-gatherco-credit-wrap .earn-value-content p.text-left-get { margin: 0 0 30px 0 !important }
    .home-gatherco-credit-wrap .earn-value-content .white-bordered-btn { text-align: center; float: left }
    .home-gatherco-credit-wrap .two-shade-content { padding: 60px 20px !important }
    .make-us-left .section-description { margin-bottom: 20px }
    .form-submit-btn .eligibility-criteria a { font-size: 14px }
    .mobile-column-heading { margin-bottom: 0 }
    .serface-tab-filter-content .slick-list { margin: 0 -7px !important }
    .serface-tab-filter-content .slick-list .slick-slide { padding: 0 7px }
    .product-label { right: -5px; height: 152px }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .earn-value-content p { margin-bottom: 15px !important;max-width: 100%;}
    .earn-value-content label { font-size: 30px; line-height: 38px }
    .search-resource-list { width: 100%; float: left; margin: 0; padding: 0 }
    .faq-section { padding: 40px 0px 40px 0px }
    .contact-type-list .project-detail-title { margin-bottom: 20px !important; font-size: 30px; line-height: 34px }
    .contact-type-list .project-detail-title br {display: none;}
    .resources-list .row { margin-left: -10px; margin-right: -10px }
    .seamless-texture-image h3.title-lexend-21 span[class^="icon-"], .resources h3.title-lexend-21 span[class^="icon-"] { margin-right: 5px }
    a.download-seamless-texture-image { margin-right: 3px }
    .scrollable-area .spacing .accordion-body .grout-colours-main .grout-colours { flex: 0 0 100%; max-width: 100% }
    .widget-item.right-panel { margin-top: 23px }
    .info-table { margin-top: 33px }
    .info-table .table td h6 { font-size: 14px }
    .info-table .table tr th .show-in-mobile { font-size: 15px }
    .info-table .table th:first-child, .info-table .table td:first-child { max-width: 33.33% !important }
    .info-table .table tr th, .info-table .table tr td { max-width: 33.33%; font-size: 11px; margin: 0 }
    .info-table-order .table tr td .deposite-paid { margin: 0 }
    .info-table-order .table tr td .ex-date { text-align: left }
    .info-table.info-table-order .table-responsive .table td p.ex-date { text-align: left }
    .sample-detail-inner img { width: 54px; height: 54px; margin: 0 }
    .sample-detail-bottom ul li .sample-detail-inner h4 { margin-left: 10px; line-height: 15px; word-break: break-all }
    .sample-detail-bottom.in-two-col ul li { padding: 0 15px 0 15px }
    .smaple-wrapper { margin-top: 34px; padding-bottom: 40px }
    .smaple-wrapper .sample-detail-left a { left: 50%; line-height: 12px; bottom: 12px }
    .favourite-slide-wrapper { margin-top: 34px }
    .favourite-slide-wrapper .slick-dots { margin: 0; padding: 0; bottom: 0 }
    .favourite-slide-wrapper .slick-dots li { width: auto; height: auto }
    .favourite-slide-wrapper .slick-dots li button { width: 8px; height: 8px; background: transparent; opacity: 1; border: 1px solid #222324; margin: 0 0; border-radius: 50%; padding: 0 }
    .favourite-slide-wrapper .slick-dots li button::before { display: none }
    .favourite-slide-wrapper .slick-dots li.slick-active button { background-color: #222324 }
    .favourite-slide-inner-wrapper { padding-bottom: 1px !important }
    .user-detail-table ul { flex-wrap: wrap }
    .user-detail-table ul li { width: 50%; margin: 0 0 20px 0 }
    .quotes-active-inner .quotes-active-right p { margin-top: 6px }
    .active-quote-added-product .item-image { width: 45px; height: 45px; margin-right: 17px }
    .item-other-details { width: calc(100% - 62px) }
    .active-quote-added-product .item-price { margin-top: 3px }
    .active-quote-added-product .added-item-list ul li .added-item-detail .item-other-details .show-more-details { margin: 26px 0 0 0; padding: 0 }
    .active-quote-added-product .added-item-list ul li .added-item-detail .item-other-details .show-more-details ul { width: 100% }
    .active-quote-added-product .added-item-list ul li .added-item-detail .item-other-details .show-more-details .lead-time { width: auto; float: left }
    .more-details label { min-width: 80px }
    .active-quote-added-product .lead-time h3 { margin-top: 25px }
    .quotes-active-inner .company-info-wrapper h3.tab-title { margin-bottom: 16px }
    .quote-order-border-bg .company-info-wrapper .warning { padding: 0 0 0 30px; background-position: 10px }
    .quotes-active-inner .company-info-wrapper .company-form .common-label { font-weight: 600 }
    .quotes-active-inner .company-info-wrapper .company-form .common-value { font-size: 13px }
    .quotes-active-inner .company-info-wrapper .company-form .form-control { font-size: 13px }
    .quotes-active-inner .company-info-wrapper .company-form .col-6 { flex: 0 0 100%; max-width: 100% }
    .new-credir ul li .active-price-item .price-left .price-label { height: 22px; padding: 0 6px; line-height: 22px; margin-left: 5px; font-size: 8px }
    .pallet-section, .pallet-box { font-size: 9px }
    .new-credir-earn-right { border-bottom: 0 !important }
    .new-credir-earn-right h4 { margin-bottom: 4px }
    .new-credir-earn-right p { font-size: 15px; padding: 0 }
    .new-credir-earn-left, .new-credir-earn-right ul li { padding: 12px 15px }
    .add-address-inner .zone-info-main { right: 0; top: 36px }
    .new-credir-earn-bottom .new-credir-earn-bottom-left .row { margin: 0 }
    .leadtime { padding-top: 10px; padding-bottom: 10px }
    .leadtime:not(.delievry-leadtime) h3 { margin: 0 0 }
    .show-more-details ul li { width: 100% }
    .order-table-header-left { padding-left: 0; display: flex; align-items: center }
    .order-table-header-left span.icon-info { top: 0; transform: none }
    .day-btn small { font-size: 10px }
    .day-btn { margin-bottom: 4px }
    .order-table-project-col .border-right, .order-table-project-wrapper .warehouse-arrival { padding-top: 10px; padding-bottom: 10px }
    .order-table-action-column .pending-delivery-approvel-btn { margin-top: 0 }
    .total-amount { font-size: 17px }
    .order-table-footer .order-progress-meter { overflow: hidden }
    .order-progress-meter ul { padding: 15px 25px; overflow: auto }
    .quote-list-inner { margin: 0 auto }
    .company-info-form .company-info-wrapper .add-address-inner .user-assignedto.delivery-address .common-value+.zone-info-main { top: 5px }
    .quotes-active-title+.quote-expired-message .quote-expired-right .orange-bg-btn2 { height: 30px; padding: 0 20px !important; line-height: 30px; min-width: 101px; font-size: 12px }
    .quotes-active-wrapper .active-quote-added-product+.company-info-form { margin-top: 30px }
    .new-credir-earn-bottom-right .export-btn { max-width: 170px; margin: 0 auto }
    .table-responsive .table tbody tr td:nth-child(6), .table-responsive .table tbody tr td:nth-child(7) { text-align: left !important }
    .new-credir-earn-bottom-right { margin-bottom: 35px }
    .new-address-field-container .recipient-btn-container .deliver-button .grey-bordered-btn { font-size: 12px; padding: 4px 15px; margin-bottom: 10px }
    .checkout-right-col .added-item-list ul li .added-item-detail .item-image { width: 45px; height: 45px; margin: 0 17px 0 0 }
    .checkout-right-col .added-item-list ul li .added-item-detail .item-title-price .item-name { margin-bottom: 18px }
    .checkout-right-col .item-price { margin-bottom: 18px }
    .checkout-right-col .show-more-details ul li { margin-bottom: 8px }
    .checkout-right-col .new-credir-earn-bottom.two-col-leadtime .leadtime-checkout-left { width: 100% !important; margin: 0 !important }
    .checkout-right-col .new-credir-earn-bottom.two-col-leadtime .leadtime-checkout-right { width: 100% !important; order: 1; margin: 0 0 11px 0 !important }
    .checkout-right-col .new-credir-earn-bottom.two-col-leadtime .new-credir-earn-bottom-left { width: 100% !important; text-align: center; display: flex !important; align-items: center }
    .checkout-right-col .new-credir-earn-bottom.two-col-leadtime .leadtime-checkout-left h3 { width: auto; display: inline-block; margin: 0 20px !important }
    .price-label { font-size: 8px; line-height: 13px; padding: 4px 3px; margin-left: 2px }
    .checkout-left-col .checkout-continue-return .btn { margin: 0 0 26px 0 }
    .checkout-page-container .checkout-footer { margin-top: 20px }
    .payment-option-wdget>label { min-width: 170px }
    .payment-option-wdget { margin: 35px 0px 30px 0 }
    #company_settings .btn-address-remove { padding: 0 }
    #userProfile .company-information-form label.default+.address-viewonly { padding: 0 77px 0 0 }
    .account-settings-form .profile-form .row .col { margin-bottom: 10px }
    .profile-form .btn-row .grey-bordered-btn { width: auto; min-width: 150px }
    .profile-form .btn-row .white-bg-btn { width: auto; border: 2px solid #222324; min-width: 150px }
    .company-information-form #company_settings .col .btn { width: auto; min-width: 130px !important }
    .company-information { margin-top: 20px }
    .gc-credit-transaction-table .table-heading { flex-wrap: wrap }
    .gc-credit-transaction-table .table-heading .table-heading-left { width: 100%; margin: 0 0 10px 0; padding: 0 }
    .gc-credit-transaction-table .table-heading .table-heading-right { flex-wrap: wrap }
    .gc-credit-transaction-table .table-heading .table-heading-right h4 { margin: 0 0 6px 0; padding: 0 !important }
    .gc-credit-transaction-table .sample-table-container table tbody tr { display: table-row }
    .gather-earn-value { margin-top: 0 }
    .accumulated-credit .quote-tab-content { padding: 0 0 }
    .gatherco-credit-container { flex-wrap: wrap }
    .gatherco-credit-container .gatherco-credit-modal-left { width: 100%; border: 0 }
    ::no-deep app-account-gather-commitment-modal .commitment-credit .modal-header { border-bottom: 1px solid #dee2e6 !important; padding: 23px 1rem !important }
    ::no-deep app-account-gather-commitment-modal .commitment-credit .modal-header .close { top: 26px !important }
    .commitment-credit .gatherco-credit-modal-left .gatherco-modal-content h3 { margin-bottom: 15px; font-size: 20px }
    .gather-card .smile-logo img { width: auto; max-width: 100% }
    .gatherco-credit-modal-right { display: inline-block; width: 100%; right: 0; height: auto; padding-bottom: 30px }
    .gatherco-credit-modal-right .tab-content .default-quote-placeholder { min-height: auto }
    /*.gatherco-credit-modal-right .tab-content .default-quote-placeholder .default-quote-placeholder { margin: 0 !important; padding: 50px 0 !important }*/
    .tab-title-main { margin-top: 63px !important; margin-bottom: 14px !important }
    .favourite-product-list>ul>li .product-box { margin-bottom: 11px }
    .favourite-product-list { padding: 0 0 }
    .favourite-product-list .product-detail .dropdown .btn .fa { font-size: 17px }
    .favourite-product-list .product-detail .dropdown .btn .fa::before { font-family: 'Font Awesome 5 Pro' }
    .heading-text { font-size: 18px; line-height: 25px }
    /*.smaple-wrapper .table-heading .table-heading-left { flex-wrap: wrap }*/
    /*.smaple-wrapper .table-heading .table-heading-left .heading-text { width: 100%; margin: 0 0 10px 0 }*/
    /*.sample-table-container .linkclass td a { border: 2px solid #222324; line-height: 24px; height: 28px }*/
    .sample-order-modal .order-complete-modal-container { flex-wrap: wrap }
    .sample-order-modal .order-complete-modal-container .sample-product-list { width: 100%; max-width: 100% !important; height: auto !important; border-right: 0 !important; padding: 20px 0 20px 0 !important; max-height: 520px }
    .sample-order-modal .order-complete-product-list .sample-detail-bottom .sample-product-detail li { width: 100%; flex: 0 0 100%; max-width: 100%; border: 0 }
    .sample-detail-inner .product-info .custom-product-price { width: 100% !important; text-align: left }
    .sample-order-modal .sample-detail-bottom ul li .sample-detail-inner .product-info h4 { margin: 0 0 10px 20px !important }
    .sample-order-modal .order-complete-modal-container .order-complete-modal-right { width: 100% !important; height: auto !important; padding: 20px 0 !important }
    .sample-order-modal .progress-step.inprogress::before { max-width: 21px }
    .sample-order-modal .tracking-progress ul::before { left: 25px; width: calc(100% - 50px); top: 26% }
    .product-enlarge-gallery .swiper-slide { width: 100% !important; padding: 0 15px }
    .grout-color-widget .color-options ul { padding: 0 0 }
    .grout-color-widget .color-options li .color-item .color-detail { width: calc(100% - 67px); display: inline-block; margin: 0; padding: 0 }
    .finishesModal .modal-body { height: auto !important }
    .moreInformationModal .modal-header { padding: 20px 15px 15px; justify-content: flex-start }
    .moreInformationModal .modal-header .title-lexend-26 { font-size: 20px }
    .format-sizing .surface-sizing-tbl-wrap table tr td { width: auto; max-width: 100%; display: inline }
    .videoPreviewModal .modal-dialog-centered { max-width: 100% !important; margin: 0 !important; height: 100% !important }
    .videoPreviewModal .modal-dialog-centered .modal-content { width: 100% !important; border-radius: 0 !important; height: 100% !important }
    .left-product-list ul li .product-item a { padding: 5px 0px 5px 0px }
    .left-product-list ul li .product-item label { font-size: 12px; margin: 0 0 0 10px }
    .slip-rating-widgets { padding: 15px 20px }
    .product-image img { min-height: 0 !important }
    .favourite-product-list>ul>li.no-records { width: 100% !important }
    .form-field.checkout-terms-condition.address-item-box a.text-decoration { font-size: 13px; line-height: normal }
    .form-field.checkout-terms-condition.address-item-box .payment-terms-condition { line-height: normal !important }
    .product-options-bottom { padding: 0 5px 8px !important }
    .product-name-option { padding: 5px 8px !important }
    .product-name-option h5 { font-size: 9px !important; line-height: 19px !important }
    .product-share { line-height: 30px !important }
    .product-share a { font-size: 12px !important; font-size: 12px }
    .product-detail-title .dropdown .fa { font-family: 'Font Awesome 5 Pro' }
    .my-order-panel .panel-body .added-item-list ul li .added-item-detail .item-image img { width: 55px; height: auto }
    .minimum-amout-modal-container .title-lexend-28 { font-size: 22px; line-height: normal }
    .mini-quote-panel .quote-panel-new-quote .my-order-total .size-meter { width: 79px; margin-right: 5px; margin-bottom: 10px; }
    .left-product-list ul li .product-item { min-width: 170px; margin-right: 5px }
    .large-modal .modal-dialog { margin: 0 auto; width: 100% }
    .imageEnlarge .product-list-navigation { display: block }
    .info-table .table tr:first-child td { width: 50% !important }
    .info-table.info-table-order .table td:first-child { max-width: 50% !important }
    .info-table-order .table tr th, .info-table-order .table tr td { width: 50% !important }
    .looking-for-more .modal-dialog .modal-content .content button.btn.btn-default { margin: 20px 0 0 15px !important }
    .looking-for-more .modal-dialog .modal-content .col-md-4 { width: 100% !important; margin: 0 0 40px 0 !important; order: 1 }
    .gather-commitment-two-shade .earn-value-content { text-align: left }
    .sample-order-modal .sample-detail-bottom ul li .sample-detail-inner .product-info .product-other-detail { clear: both }
    .format-sizing .surface-sizing-tbl-wrap table tr td span.fa.fa-info { display: none }
    /*.smaple-wrapper .table-heading .table-heading-left h4 { width: 100%; display: flex; align-items: center; margin: 0 0 10px 0; padding: 0 10px 0 0 }*/
    .samples-modal-popup .modal-header .modal-title { font-size: 19px }
    .order-progress-meter ul li { position: relative; padding: 0 10px }
    .order-progress-meter ul li:last-child::after { display: none }
    .info-table-order .table tr td .deposite-paid label { text-align: center }
    .smaple-wrapper .table-heading .background-button { background: #fff !important; color: #222324 !important; border-color: #222324 !important }
    .navbar-collapse .navbar-nav { height: calc(100vh - 140px) !important; max-height: calc(100vh - 60px) !important }
    .looking-for-more .modal-dialog { max-width: 95% !important }
    .looking-body { width: 100% !important; margin: 0 }
    .looking-for-more .modal-dialog .modal-content .content h2 { width: 100% !important; margin: 84px 0 0 15px !important; font-size: 25px !important; line-height: normal !important }
    .looking-for-more .modal-dialog .modal-content .content p { width: 100% !important; margin: 30px 0 0 15px !important }
    .looking-for-more .modal-dialog .modal-content .col-md-8 { width: 100% !important; margin: 0; padding: 0; order: 0 }
    .two-shade-left .earn-value-content p { margin: 0 auto!important }
    .two-shade-left .earn-value-content.go-commit-left-text p { margin: 0 !important;max-width: 100%;}
    .large-modal-for-gathercommitment { top: 0 !important }
    .pricing-table-container { flex-wrap: wrap }
    .pricing-table-left { width: 100% }
    .pricing-table-container .pricing-table-right { width: 100% }
    .left-product-list ul { display: flex; flex-wrap: wrap }
    .pricing-page .pricing-table-widget.sticky .pricing-table-widget-header { position: static }
    .left-product-list ul li.active .product-item { margin-bottom: 10px }
    .pricing-page .pricing-table-widget .format-sizing-table { max-height: 100% }
    .order-table-action-column .order-number { width: 73px !important }
    .gather-commitment-credit-wrapper .credit-box { margin-bottom: 0 !important }
    .large-modal .modal-dialog .modal-content { height: 100vh !important; overflow: auto; border-radius: 0 !important }
    .imageEnlarge .product-gallery-image { height: 100% !important }
    .price-boxes .price-meter-box span { width: 200px !important }
    /*.commitment-credit .active-quote-price-container ul li.grey-box-price .price-meter-container .price-boxes .credit-box { justify-content: center }*/
    .gatherco-credit-modal-right .tab-content .default-quote-placeholder .default-quote-placeholder .title-lexend-20 { text-align: center; font-size: 19px !important; padding-top: 0 !important }
    .sample-table-container .dashboard-height-account tr .text-orange.status-column .progress-step { margin: 0 7px 0 auto !important; width: auto; max-width: 61px }
    .earn-value-content { text-align: center !important }
    .my-order-panel .panel-body { padding: 26px 16px 0 !important }
    .info-table-order .table td label { text-align: left }
    .info-table .table td .expiry-date { margin: 0 !important; padding: 0 40px }
    .info-table.info-table-order .table td:last-child { display: none !important }
    .info-table-order .table tr td.text-right { width: 50% !important }
    .info-table .table td .circle-view-btn, .circle-view-btn { border: 2px solid #222324 !important }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .two-shade-left .plant-image { text-align: center; }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .groutColor .grout-colour-range .modal-header { justify-content: flex-start }
    .groutColor .grout-colour-range .modal-header .title-lexend-26 { font-size: 16px }
    .groutColor .grout-colour-range .modal-header .close { top: 28px }
    .product-item-detail .product-detail-right { display: flex }
    .product-list-next { right: 10px !important; }
    .product-list-prev { left: 20px }
    .product-detail-left h4 { font-size: 12px; line-height: 15px }
    .product-item-detail .product-detail-right .favourite-btn { width: 27px; height: 27px }
    .product-item-detail .product-detail-right .favourite-btn .icon-heart.active:before { background-size: 15px auto }
    .product-item-detail .product-detail-right .favourite-btn .icon-heart:before { background-size: 15px auto }
    .product-item-detail .product-detail-right .favourite-btn .icon-play::before { background-size: 15px auto }
    .color-thumb a img, .color-palette-thumb a img { width: 24px !important; height: 24px !important }
    .product-color-palette ul li .color-palette-thumb a { width: 24px !important; height: 24px !important }
    .product-color-palette ul li .color-palette-thumb .color-palette-name { font-size: 12px; height: 24px; line-height: 24px; }
    .product-color-palette ul li .color-palette-thumb a { width: 24px !important; height: 24px !important }
    .product-gallery-main .product-gallery-image img { max-height: 100%; max-width: 100% }
    .surface-tab-section .serface-tab-filter-content .product-gallery .align-items-center .product-gallery-text { width: 100%; margin: 30px 0 0 0 }
    .surface-tab-section .serface-tab-filter-content .product-gallery .align-items-center .product-gallery-images { padding: 40px 0 0; width: 100% }
    .navbar-toggler { margin: 0 0 0 0 }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .earn-value-content p { margin-bottom: 15px !important }
    .page-banner-section { min-height: 400px }
    .earn-value-content label { font-size: 30px; line-height: 40px }
    .gather-commitment-two-shade .two-shade-content { padding-left: 25px }
    .reward-earn-widget h3 { font-size: 40px; margin-bottom: 40px; line-height: normal }
    .reward-row+.reward-row { margin-top: 50px; flex-direction: inherit }
    .earn-value-content p { margin: 0 0 }
    .widget-item.right-panel { margin-top: 23px }
    .info-table { margin-top: 33px }
    .info-table .table td h6 { font-size: 14px }
    .info-table .table tr th .show-in-mobile { font-size: 15px }
    .info-table .table th:first-child, .info-table .table td:first-child { max-width: 33.33% !important }
    .info-table .table tr th, .info-table .table tr td { max-width: 33.33%; font-size: 11px; margin: 0 }
    .info-table-order .table tr td .deposite-paid { margin: 0 0 }
    .info-table-order .table tr td .ex-date { text-align: left }
    .info-table.info-table-order .table-responsive .table td p.ex-date { text-align: left }
    .sample-detail-inner img { width: 54px; height: 54px; margin: 0 }
    .sample-detail-bottom ul li .sample-detail-inner h4 { margin-left: 10px; line-height: 15px; word-break: break-all }
    .sample-detail-bottom.in-two-col ul li { padding: 0 15px 0 15px }
    .smaple-wrapper { padding-bottom: 40px; margin-top: 34px }
    .smaple-wrapper .sample-detail-left a { left: 50%; line-height: 12px; bottom: 12px }
    .favourite-slide-wrapper { margin-top: 34px }
    .favourite-slide-wrapper .slick-dots { margin: 0; padding: 0; bottom: 0 }
    .favourite-slide-wrapper .slick-dots li { width: auto; height: auto }
    .favourite-slide-wrapper .slick-dots li button { width: 8px; height: 8px; background: transparent; opacity: 1; border: 1px solid #222324; margin: 0 0; border-radius: 50%; padding: 0 }
    .favourite-slide-wrapper .slick-dots li button::before { display: none }
    .favourite-slide-wrapper .slick-dots li.slick-active button { background-color: #222324 }
    .favourite-slide-inner-wrapper { padding-bottom: 1px !important }
    .user-detail-table ul { flex-wrap: wrap }
    .user-detail-table ul li { width: 50%; margin: 0 0 20px 0 }
    .info-table .table td .expiry-date { margin: 0 }
    /*.table-heading h4 { padding-left: 15px }*/
    .quotes-active-inner .quotes-active-right p { margin-top: 6px }
    .active-quote-added-product .item-image { width: 45px; height: 45px; margin-right: 17px }
    .item-other-details { width: calc(100% - 62px) }
    .active-quote-added-product .item-price { margin-top: 3px }
    .active-quote-added-product .added-item-list ul li .added-item-detail .item-other-details .show-more-details { margin: 26px 0 0 0; padding: 0 }
    .active-quote-added-product .added-item-list ul li .added-item-detail .item-other-details .show-more-details ul { width: 100% }
    .active-quote-added-product .added-item-list ul li .added-item-detail .item-other-details .show-more-details .lead-time { width: auto; float: left }
    .more-details label { min-width: 80px }
    .active-quote-added-product .lead-time h3 { margin-top: 25px }
    .quotes-active-inner .company-info-wrapper h3.tab-title { margin-bottom: 16px }
    .quote-order-border-bg .company-info-wrapper .warning { padding: 0 0 0 30px; background-position: 10px }
    .quotes-active-inner .company-info-wrapper .company-form .common-label { font-weight: 600 }
    .quotes-active-inner .company-info-wrapper .company-form .common-value { font-size: 13px }
    .quotes-active-inner .company-info-wrapper .company-form .form-control { font-size: 13px }
    .quotes-active-inner .company-info-wrapper .company-form .col-6 { flex: 0 0 100%; max-width: 100% }
    .new-credir ul li .active-price-item .price-left .price-label { height: 22px; padding: 0 6px; line-height: 22px; margin-left: 5px; font-size: 8px }
    .pallet-box { font-size: 9px }
    .new-credir-earn-right { border-bottom: 0 !important }
    .new-credir-earn-right h4 { margin-bottom: 4px }
    .new-credir-earn-right p { font-size: 15px; padding: 0 }
    .new-credir-earn-left, .new-credir-earn-right ul li { padding: 12px 15px }
    .add-address-inner .zone-info-main { right: 0; top: 36px }
    .new-credir-earn-bottom .new-credir-earn-bottom-left .row { margin: 0 }
    .leadtime { padding-top: 10px; padding-bottom: 10px }
    .leadtime:not(.delievry-leadtime) h3 { margin: 0 0 }
    .order-table-header-left { padding-left: 0; display: flex; align-items: center }
    .order-table-header-left span.icon-info { top: 0; transform: none }
    .day-btn small { font-size: 10px }
    .day-btn { margin-bottom: 4px }
    .order-table-project-col .border-right, .order-table-project-wrapper .warehouse-arrival { padding-top: 10px; padding-bottom: 10px }
    .order-table-action-column .pending-delivery-approvel-btn { margin-top: 0 }
    .total-amount { font-size: 17px }
    .order-table-footer .order-progress-meter { overflow: hidden }
    .order-progress-meter ul { padding: 15px 25px; overflow: auto }
    .orders-component-faq .faq-section .row .col-md-4 { width: 100%; flex: 0 0 100%; max-width: 100% }
    .tab-inner-container { padding: 40px 20px }
    .quote-list-inner { margin: 20px auto 0 }
    #quotes .quotes-list.trade-quote-footer { margin-top: 30px }
    .company-info-form .company-info-wrapper .add-address-inner .user-assignedto.delivery-address .common-value+.zone-info-main { top: 5px }
    .quotes-active-title+.quote-expired-message .quote-expired-right .orange-bg-btn2 { height: 30px; padding: 0 20px !important; line-height: 30px; min-width: 101px; font-size: 12px }
    .quotes-active-wrapper .active-quote-added-product+.company-info-form { margin-top: 30px }
    .new-credir-earn-bottom-right .export-btn { max-width: 160px; margin: 0 auto }
    .new-credir-earn-bottom-right { margin-bottom: 35px }
    .checkout-right-col .added-item-list ul li .added-item-detail .item-image { width: 45px; height: 45px; margin: 0 17px 0 0 }
    .checkout-right-col .added-item-list ul li .added-item-detail .item-title-price .item-name { margin-bottom: 18px }
    .checkout-right-col .item-price { margin-bottom: 18px }
    .checkout-right-col .new-credir-earn-bottom.two-col-leadtime .leadtime-checkout-left { width: 100% !important; margin: 0 !important }
    .checkout-right-col .new-credir-earn-bottom.two-col-leadtime .leadtime-checkout-right { width: 100% !important; order: 1; margin: 0 0 11px 0 !important }
    .checkout-right-col .new-credir-earn-bottom.two-col-leadtime .new-credir-earn-bottom-left { width: 100% !important; text-align: center; display: flex !important; align-items: center }
    .checkout-right-col .new-credir-earn-bottom.two-col-leadtime .leadtime-checkout-left h3 { width: auto; display: inline-block; margin: 0 20px !important }
    .price-label { font-size: 8px; line-height: 13px; padding: 4px 3px; margin-left: 2px }
    .checkout-left-col .checkout-continue-return .btn { margin: 0 0 26px 0 }
    .checkout-page-container .checkout-footer { margin-top: 20px }
    .checkout-right-col .show-more-details ul li { width: 100% !important; margin-bottom: 8px }
    .profile-form { margin: 0 auto }
    .gc-credit-transaction-table .table-heading { flex-wrap: wrap }
    .gc-credit-transaction-table .table-heading .table-heading-left { width: 100%; margin: 0 0 10px 0; padding: 0 }
    .gc-credit-transaction-table .table-heading .table-heading-right { flex-wrap: wrap }
    .gc-credit-transaction-table .table-heading .table-heading-right h4 { margin: 0 0 6px 0 }
    .gc-credit-transaction-table .sample-table-container table tbody tr { display: table-row }
    .gather-earn-value { margin-top: 0 }
    .accumulated-credit .quote-tab-content { padding: 0 0 }
    .gatherco-credit-container { flex-wrap: wrap }
    .gatherco-credit-container .gatherco-credit-modal-left { width: 100%; border: 0 }
    .commitment-credit .gatherco-credit-modal-left .gatherco-modal-content h3 { margin-bottom: 15px; font-size: 20px }
    .left-sidebar .nav .nav-item .nav-link>span { margin-right: 5px !important; }
    .left-sidebar .nav .nav-item .nav-link { font-size: 12px !important; padding-left: 10px!important;}
    .gatherco-credit-modal-right { display: inline-block; width: 100%; right: 0; height: auto; padding-bottom: 30px }
    .gatherco-credit-modal-right .tab-content .default-quote-placeholder { min-height: auto }
    /*.gatherco-credit-modal-right .tab-content .default-quote-placeholder .default-quote-placeholder { margin: 0 !important; padding: 50px 0 !important }*/
    .gatherco-credit-modal-right .tab-content .default-quote-placeholder .default-quote-placeholder .title-lexend-20 { padding-top: 0 !important }
    .gatherco-credit-modal-right .active-quote-price-container .grey-box-price .credit-box .price-meter-box { margin: 0 auto }
    .tab-title-main { margin-top: 63px !important; margin-bottom: 14px !important }
    .product-image img { min-height: 0 !important }
    .product-options-bottom { padding: 0 5px 8px !important }
    .product-name-option { padding: 5px 15px !important }
    .product-name-option h5 { font-size: 12px !important; line-height: 19px !important }
    .product-share { line-height: 30px !important }
    .product-share a { font-size: 12px !important; font-size: 12px }
    .favourite-product-list>ul>li .product-box { margin-bottom: 11px }
    .favourite-product-list { padding: 0 0 }
    .favourite-product-list .product-detail .dropdown .btn .fa { font-size: 17px }
    .favourite-product-list .product-detail .dropdown .btn .fa::before { font-family: 'Font Awesome 5 Pro' }
    .heading-text { font-size: 18px; line-height: 25px }
    /*.smaple-wrapper .table-heading .table-heading-left { flex-wrap: wrap }*/
    /*.smaple-wrapper .table-heading .table-heading-left .heading-text { width: 100%; margin: 0 0 10px 0 }*/
    /*.smaple-wrapper .table-heading .table-heading-left h4 { margin: 0 0 10px 0; padding: 0 10px 0 0 }*/
    /*.sample-table-container .linkclass td a { border: 2px solid #222324; line-height: 24px; height: 28px }*/
    .sample-order-modal .order-complete-modal-container { flex-wrap: wrap }
    .sample-order-modal .order-complete-modal-container .sample-product-list { width: 100%; max-width: 100% !important; height: auto !important; border-right: 0 !important; padding: 20px 0 20px 0 !important; max-height: 520px }
    .sample-order-modal .order-complete-modal-container .order-complete-modal-right { width: 100% !important; height: auto !important; padding: 20px 0 !important }
    .sample-order-modal .progress-step.inprogress::before { max-width: 21px }
    .sample-order-modal .tracking-progress ul::before { left: 25px; width: calc(100% - 50px); top: 26% }
    .format-sizing .surface-sizing-tbl-wrap table tr td { width: auto; max-width: 100% }
    .texture-guide-modal-container { height: auto !important }
    .my-order-panel .panel-body .added-item-list ul li .added-item-detail .item-image img { width: 60px; height: auto }
    .grout-color-widget .color-options ul { justify-content: flex-start; padding: 0 0 }
    .looking-for-more .modal-dialog { max-width: 95% !important }
    .looking-body { width: 100% !important; margin: 0 }
    .looking-for-more .modal-dialog .modal-content .content button.btn.btn-default { margin: 20px 0 0 15px !important }
    .looking-for-more .modal-dialog .modal-content .col-md-4 { width: 100% !important; margin: 0 0 40px 0 !important; order: 1; flex: 0 0 100%; max-width: 100% }
    .looking-for-more .modal-dialog .modal-content .content h2 { width: 100% !important; margin: 20px 0 0 15px !important; font-size: 25px !important; line-height: normal !important }
    .looking-for-more .modal-dialog .modal-content .content p { width: 100% !important; margin: 10px 0 0 15px !important }
    .looking-for-more .modal-dialog .modal-content .col-md-8 { width: 100% !important; margin: 0; padding: 0; order: 0; flex: 0 0 100%; max-width: 100% }
    .info-table .table tr:first-child td { width: 50% !important }
    .info-table.info-table-order .table td:first-child { max-width: 50% !important }
    .info-table-order .table tr th, .info-table-order .table tr td { width: 50% !important; max-width: 50% }
    .info-table .table td .circle-view-btn, .circle-view-btn { border: 2px solid #222324 !important }
    .info-table.info-table-order .table td:last-child { display: none !important }
    .order-progress-meter ul li { position: relative; padding: 0 10px }
    .order-progress-meter ul li:last-child::after { display: none }
    .pricing-table-container .pricing-table-right { width: calc(100% - 270px) }
    .left-product-list ul li .product-item label { font-size: 12px }
    .pricing-page .pricing-table-widget .format-sizing-table { max-height: 100% }
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .surface-tab-section .serface-tab-filter-content .product-gallery .align-items-center .product-gallery-images { padding: 0 0; width: 543px }
    .product-list-next { right: 20px }
    .product-list-prev { left: 20px }
    .product-detail-left h4 { font-size: 12px; line-height: 15px }
    .product-item-detail .product-detail-right .favourite-btn { width: 27px; height: 27px }
    .product-item-detail .product-detail-right .favourite-btn .icon-heart.active:before { background-size: 18px auto }
    .product-item-detail .product-detail-right .favourite-btn .icon-heart:before { background-size: 18px auto }
    .product-item-detail .product-detail-right .favourite-btn .icon-play::before { background-size: 18px auto }
    .color-thumb a img, .color-palette-thumb a img { width: 24px !important; height: 24px !important }
    .product-color-palette ul li .color-palette-thumb a { width: 24px !important; height: 24px !important }
    .product-color-palette ul li .color-palette-thumb .color-palette-name { font-size: 12px; height: 24px; line-height: 24px; }
    .product-color-palette ul li .color-palette-thumb a { width: 24px !important; height: 24px !important }
    .product-gallery-main .product-gallery-image img { max-height: 100%; max-width: 100% }
    .navbar-toggler { margin: 0 0 0 0 }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .two-shade-left { width: 50%; text-align: left }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .earn-value-content p { margin-left: 0; margin-right: 0 }
    .page-banner-title { font-size: 60px; line-height: 70px }
    .page-banner-title span { font-size: 60px; line-height: 70px }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .two-shade-left .plant-image.show-in-mobile { display: none !important }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .two-shade-right.bg-transparent { display: block }
    .gatherco-credit-section:not(.home-gatherco-credit-wrap) .two-shade-right.bg-transparent .hide-in-mobile { display: block !important }
    .gather-commitment-two-shade .two-shade-content { padding-left: 40px; padding-right: 40px }
    .widget-item.right-panel { margin-top: 23px }
    .info-table { margin-top: 33px }
    .info-table .table td h6 { font-size: 14px }
    .info-table .table tr th .show-in-mobile { font-size: 15px }
    .info-table .table th:first-child, .info-table .table td:first-child { max-width: 33.33% !important }
    .info-table .table tr th, .info-table .table tr td { max-width: 33.33%; font-size: 11px; margin: 0 }
    .info-table-order .table tr td .deposite-paid { margin: 0 }
    .info-table-order .table tr td .ex-date { text-align: left }
    .info-table.info-table-order .table-responsive .table td p.ex-date { text-align: left }
    .sample-detail-inner img { width: 54px; height: 54px; margin: 0 }
    .sample-detail-bottom ul li .sample-detail-inner h4 { margin-left: 10px; line-height: 15px }
    .sample-detail-bottom.in-two-col ul li { padding: 0 15px 0 15px }
    .smaple-wrapper { padding-bottom: 40px; margin-top: 34px }
    .smaple-wrapper .sample-detail-left a { left: 50%; line-height: 12px; bottom: 12px }
    .favourite-slide-wrapper { margin-top: 34px }
    .favourite-slide-wrapper .slick-dots { margin: 0; padding: 0; bottom: 0 }
    .favourite-slide-wrapper .slick-dots li { width: auto; height: auto }
    .favourite-slide-wrapper .slick-dots li button { width: 8px; height: 8px; background: transparent; opacity: 1; border: 1px solid #222324; margin: 0 0; border-radius: 50%; padding: 0 }
    .favourite-slide-wrapper .slick-dots li button::before { display: none }
    .favourite-slide-wrapper .slick-dots li.slick-active button { background-color: #222324 }
    .favourite-slide-inner-wrapper { padding-bottom: 1px !important }
    .info-table .table td .expiry-date { margin: 0 }
    /*.table-heading h4 { padding-left: 15px }*/
    .quotes-active-inner .quotes-active-right p { margin-top: 6px }
    .active-quote-added-product .item-price { margin-top: 3px }
    .active-quote-added-product .added-item-list ul li .added-item-detail .item-other-details .show-more-details { margin: 26px 0 0 0; padding: 0 }
    .active-quote-added-product .added-item-list ul li .added-item-detail .item-other-details .show-more-details ul { width: 100% }
    .active-quote-added-product .added-item-list ul li .added-item-detail .item-other-details .show-more-details .lead-time { width: auto; float: left }
    .more-details label { min-width: 80px }
    .active-quote-added-product .lead-time h3 { margin-top: 25px }
    .quotes-active-inner .company-info-wrapper h3.tab-title { margin-bottom: 16px }
    .quote-order-border-bg .company-info-wrapper .warning { padding: 0 0 0 30px; background-position: 10px }
    .quotes-active-inner .company-info-wrapper .company-form .common-label { font-weight: 600 }
    .quotes-active-inner .company-info-wrapper .company-form .common-value { font-size: 13px }
    .quotes-active-inner .company-info-wrapper .company-form .form-control { font-size: 13px }
    .quotes-active-inner .company-info-wrapper .company-form .col-6 { flex: 0 0 100%; max-width: 100% }
    .new-credir ul li .active-price-item .price-left .price-label { height: 22px; padding: 0 6px; line-height: 22px; margin-left: 5px; font-size: 8px }
    .pallet-box { font-size: 9px }
    .new-credir-earn-right { border-bottom: 0 !important }
    .new-credir-earn-right h4 { margin-bottom: 4px }
    .new-credir-earn-right p { font-size: 15px; padding: 0 }
    .new-credir-earn-left, .new-credir-earn-right ul li { padding: 12px 15px }
    .add-address-inner .zone-info-main { right: 0; top: 36px }
    .new-credir-earn-bottom .new-credir-earn-bottom-left .row { margin: 0 }
    .leadtime { padding-top: 10px; padding-bottom: 10px }
    .leadtime:not(.delievry-leadtime) h3 { margin: 0 0 }
    .order-table-header-left { padding-left: 0; display: flex; align-items: center }
    .order-table-header-left span.icon-info { top: 0; transform: none }
    .new-credir-earn-bottom-right .export-btn { max-width: 160px; margin: 0 auto }
    .gc-credit-transaction-table .table-heading { flex-wrap: wrap }
    .gc-credit-transaction-table .table-heading .table-heading-left { width: 100%; margin: 0 0 10px 0; padding: 0 }
    .gc-credit-transaction-table .table-heading .table-heading-right { flex-wrap: wrap }
    .gc-credit-transaction-table .table-heading .table-heading-right h4 { margin: 0 0 6px 0 }
    .gc-credit-transaction-table .sample-table-container table tbody tr { display: table-row }
    /*.gatherco-credit-modal-right { display: inline-block; right: 0; overflow: auto; top: 0; margin-top: 30px; height: calc(90vh - 60px); padding: 0 20px }*/
    /*.gatherco-credit-modal-right .active-quote-price-container .grey-box-price .credit-box .price-meter-box { margin: 0 auto }*/
    /*.gatherco-credit-modal-right .active-quote-price-container .grey-box-price .credit-box { margin: 0 auto }*/
    /*.gatherco-credit-modal-right .active-quote-price-container .grey-box-price .final-total-box { margin: 0 auto }*/
    .donate-amount-list ul li { margin: 0 5px }
    /*.gatherco-credit-modal-right .active-quote-price-container .price-boxes { flex-wrap: wrap }*/
    .gather-earn-container { text-align: center; flex-direction: column }
    .earn-value .earn-value-title span { vertical-align: inherit }
    .favourite-product-list>ul>li { padding: 0 10px; width: 33.33% }
    .product-image img { min-height: auto; max-height: auto; }
    .sample-table-container { overflow: auto }
    .heading-text { font-size: 18px; line-height: 25px }
    /*.smaple-wrapper .table-heading .table-heading-left { flex-wrap: wrap }*/
    /*.smaple-wrapper .table-heading .table-heading-left .heading-text { width: 100%; margin: 0 0 10px 0 }*/
    /*.smaple-wrapper .table-heading .table-heading-left h4 { margin: 0 0 10px 0; padding: 0 10px 0 0 }*/
    /*.sample-table-container .linkclass td a { border: 2px solid #222324; line-height: 24px; height: 28px }*/
    .sample-order-modal .order-complete-modal-container .order-complete-modal-right { width: calc(100% - 445px) }
    .sample-order-modal .order-complete-modal-container .order-complete-modal-left { max-width: 445px }
    .seamless-texture-image h3.title-lexend-21 span[class^="icon-"], .resources h3.title-lexend-21 span[class^="icon-"] { margin-right: 5px }
    a.download-seamless-texture-image { margin-right: 3px }
    .scrollable-area .spacing .accordion-body .grout-colours-main .grout-colours { flex: 0 0 100%; max-width: 100% }
    .grout-colours-main .grout-colours { flex: 0 0 100%; max-width: 100% }
    .title-lexend-21 { font-size: 18px }
    .finish-widget.scroll-section-widget .swiper-slide { width: 50% !important }
    .texture-guide-modal-container { height: auto }
    .grout-color-widget .color-options ul { justify-content: flex-start; padding: 0 0 }
    .looking-for-more .modal-dialog { max-width: 95% !important }
    .looking-body { width: 100% !important; margin: 0 }
    .info-table .table tr:first-child td { width: 50% !important }
    .info-table.info-table-order .table td:first-child { max-width: 50% !important }
    .info-table-order .table tr th, .info-table-order .table tr td { width: 50% !important }
    .info-table .table td .circle-view-btn, .circle-view-btn { border: 2px solid #222324 !important }
    .info-table.info-table-order .table td:last-child { display: none !important }
    .pricing-table-container .pricing-table-right { width: calc(100% - 270px) }
    .left-product-list ul li .product-item label { font-size: 12px }
    .pricing-page .pricing-table-widget .format-sizing-table { max-height: 100% }
    .groutColor .grout-colour-range .modal-header { justify-content: flex-start }
    .groutColor .grout-colour-range .modal-header .title-lexend-26 { font-size: 18px }
    .groutColor .grout-colour-range .modal-header .close { top: 28px }
    .faq-section .section-inner-container{padding: 0 25px!important;}
}
@media screen and (min-width:1200px) and (max-width:1500px) {
    .product-list-prev { left: 20px }
    .product-item-detail .product-detail-right .favourite-btn { width: 27px; height: 27px }
    .product-item-detail .product-detail-right .favourite-btn .icon-heart.active:before { background-size: 18px auto }
    .product-item-detail .product-detail-right .favourite-btn .icon-heart:before { background-size: 18px auto }
    .product-item-detail .product-detail-right .favourite-btn .icon-play::before { background-size: 18px auto }
    .color-thumb a img, .color-palette-thumb a img { width: 24px !important; height: 24px !important }
    .product-color-palette ul li .color-palette-thumb { width: 24px; height: 24px; }
    .product-color-palette ul li .color-palette-thumb .color-palette-name { font-size: 12px; height: 24px; line-height: 24px; }
    .product-color-palette ul li .color-palette-thumb a { width: 24px !important; height: 24px !important }
    .product-gallery-main .product-gallery-image img { max-height: 100%; max-width: 100% }
    .product-option-right .specification-name { max-width: 100px; font-size: 10px }
    .product-container-main .product-list-navigation .product-list-prev { left: 60px }
    .gc-credit-transaction-table .table-heading .table-heading-left { width: 100%; margin: 0 0 10px 0; padding: 0 }
    .gc-credit-transaction-table .table-heading .table-heading-right { flex-wrap: wrap }
    .gc-credit-transaction-table .table-heading .table-heading-right h4 { margin: 0 0 6px 0; }
    .gatherco-credit-modal-right { padding: 0 20px }
    .gc-credit-transaction-table .sample-table-container table tbody tr { display: table-row }
    .product-image img { min-height: auto; max-height: auto; }
    .stickyHeader .price-label-column { padding-left: 30px !important }
}
@media screen and (min-width:1300px) and (max-width:1350px) {
    .product-option-right .specification-name { max-width: 100px; font-size: 10px }
}
@media screen and (min-width:1351px) and (max-width:1450px) {
    .product-option-right .specification-name { max-width: 100px; font-size: 10px }
}
@media screen and (min-width:1501px) and (max-width:1700px) {
    .product-option-right .specification-name { max-width: 100px; font-size: 10px }
}
@media screen and (min-width:320px) and (max-width:991px) {
    .product-enlarge-gallery .swiper-pagination.swiper-pagination-bullets{position: absolute!important;left: 50%!important;transform: translateX(-50%);bottom: 20px!important;}
    .reward-process-section h1 { padding-bottom: 50px; margin: 0 !important }
    .rating-detail .texture-rating-count { padding: 0 3px; display: flex; flex-wrap: wrap }
    .product-enlarge-gallery-container .imagery-detail-slider { width: 100%; float: left; margin: 0; padding: 15px 15px; position: relative; color: #fff; max-width: 380px }
    .imageEnlarge .modal-dialog .modal-content {min-height: calc(100vh - 3.5rem); display: flex; align-items: center; justify-content: center; padding: 0; flex-wrap: wrap;}
    #dashboard .btn-secondary { background-color: #222324; border-color: transparent; color: #fff }
    .info-table .table td .circle-view-btn, .circle-view-btn { border: 2px solid #222324 !important; float: right !important; margin-right: -5px !important }
    /*.surface-imagery-slider .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction { bottom: 22px !important; left: 16px !important; width: auto !important; position: fixed !important }*/
    .samples-tab-container .samples-table .sample-detail-bottom { padding: 0px 22px !important }
    .right-content .table-responsive .text-center { text-align: center !important; margin-bottom: 0 !important }
    .inner-container { padding: 30px 0 30px 68px !important }
}
@media screen and (min-width:320px) and (max-width:1100px) {
    .right-content .info-table .view-button { display: block }
    .info-table .table td .circle-view-btn, .circle-view-btn { float: right !important; margin-right: -5px }
    .text-center { text-align: center !important; margin-bottom: 12px !important }
    .signin-register-panel .panel-body { padding-bottom: 100px !important }
    .product-enlarge-gallery .swiper-wrapper .swiper-slide { width: 100% !important; max-width: 100% }
    .imageEnlarge .imagery-detail-slider { color: #fff; padding: 15px; float: left; margin: 10px 0 0 0; position: absolute; bottom: auto; width: 100% }
    .imageEnlarge .imagery-detail-slider .imagery-detail-slider-left { padding-bottom: 10px }
    .imageEnlarge .enlarge-image-options { width: 100% !important; left: auto !important; right: 0 !important; margin: 0 0 0 auto !important; position: absolute !important; z-index: 99 }
    .imageEnlarge .enlarge-image-options a { position: absolute !important; right: 55px !important }
    .imageEnlarge .enlarge-image-options a.image-download { right: 20px !important }
    .imageEnlarge .enlarge-image-options a.image-close { right: auto !important; left: 10px !important }
    .company-info-form .company-info-wrapper .add-address-inner .user-assignedto.delivery-address .common-value+.zone-info-main { top: 68px !important }
    .delete-favourite-modal .modal-body.d-flex { justify-content: center }
    .right-content .sample-wrapper-main .smaple-wrapper { padding-bottom: 0 !important }
    .right-content .sample-wrapper-main .sample-detail-bottom { padding-bottom: 45px !important }
    .two-shade-left .earn-value-content p { padding: 0 0 20px }
    .project-detail-right p { margin-bottom: 15px !important }
    .slip-rate-count { width: 35px; text-align: center; margin-right: 26px }
    .new-credir-earn-bottom .new-credir-earn-bottom-right { text-align: center !important }
    .new-credir-earn-bottom .new-credir-earn-bottom-right .btn { width: 170px !important }
}
@media screen and (min-width:992px) and (max-width:1450px) {
    .sample-table-container { overflow: auto !important }
}
@media screen and (min-width:992px) and (max-width:1200px) {
    .two-shade-content { padding: 80px 25px 80px 25px; width: 100% }
    .seamless-images, .surface-accordion .product-container-main .additional-product-formats, .surface-accordion .resources-list .resources { padding-bottom: 0 !important }
}
@media screen and (max-width:1744px) {
    .surface-accordion .resources-list .resources .col-3 { flex: 0 0 25% !important; max-width: 25% !important }
    .seamless-texture-image .seamless-images ul li { width: 25% !important; }
}
@media screen and (max-width:1404px) {
    .surface-accordion .resources-list .resources .col-3 { flex: 0 0 33.33% !important; max-width: 33.33% !important; }
    .seamless-texture-image .seamless-images ul li { width: 33.33% !important; }
}
@media screen and (max-width:1199px) {
    .surface-accordion .resources-list .resources .col-3 { flex: 0 0 50% !important; max-width: 50% !important; }
    .seamless-texture-image .seamless-images ul li { width: 50% !important; }
    .product-container-main .additional-product-formats { padding: 0 !important; }
    .active-quote-price-container .active-price-item .price-left .price-label{margin-left: 0!important;margin-top: 5px;}
}
@media screen and (max-width:869px) {
    .surface-accordion .resources-list .resources .col-3 { flex: 0 0 33.33% !important; max-width: 33.33% !important; }
    .resources-list .resources .col-3 { margin-bottom: 0 !important; }
    .seamless-texture-image .seamless-images ul li { width: 33.33% !important; }
}
@media screen and (max-width: 767px){
    .product-container-main .product-list-next {right: 20px !important;margin-top: -29px;}
    .product-list-prev {left: 20px;}
    .product-list-navigation-arrow {width: 29px;height: 29px;}
    /*.product-list-navigation-arrow span {line-height: 29px;}*/
}
@media screen and (max-width:599px) {
    .surface-accordion .resources-list .resources .col-3 { flex: 0 0 50% !important; max-width: 50% !important; }
    .resources-list .resources .col-3 { margin-bottom: 0 !important; }
    .seamless-texture-image .seamless-images ul li { width: 50% !important; }
}
@media screen and (max-width:417px) {
    .main-container .main-inner-container .surface-accordion .resources-list .resources .content-inner { min-height: 200px; max-height: 200px; }
    .resources-list .resources { padding-bottom: 0 !important; }
    .seamless-images { padding: 4px 22px 0; }
    .product-group-carousel { padding-bottom: 5px !important; }
}
@media screen and (max-width:379px) {
    .product-format{padding-right: 10px;}
    span.fa.fa-info{margin-left: 0;}
    .option-detail.quantity-options.price-btn{white-space: normal;}
    .new-added-wdget-title .option-item-box .expand-label label{font-size: 14px;}
    .adhesive-premium-sealing-inner .adhesive-total{margin-bottom: 0.5rem;font-size: 14px!important;}
    /*.active-quote-price-container .active-price-item .price-left{display: flex;flex-direction: column;}*/
    .active-quote-price-container .active-price-item .price-left .price-label{margin-left: 0!important;margin-top: 5px;}
    .checkout-right-col .active-quote-price-container .total-price .active-price-item .price-left{display: flex;flex-direction: row;}
}
@media screen and (max-width:350px) {
    .format-sizing-table ul li{font-size: 10px;}
    .product-format label{font-size: 10px;}
    .select-product-by-size .product-price{font-size: 10px;}
    .new-added-wdget-title .option-item-box .expand-label label{width: 80%;}
}
@media screen and (max-width:320px) {
    .product-color-palette ul li .color-palette-thumb .color-palette-name{font-size: 10px;line-height: 21px;}
    .surface-tab-section .surfaces-tab-filter .nav-tabs{flex-wrap: nowrap;}
}
