@import "~swiper/swiper-bundle.css";
@import "~swiper/swiper-bundle.min.css";
@import '~ngx-toastr/toastr';
/* @import '~ngx-bar-rating/themes/br-default-theme'; */
@import "~nouislider/dist/nouislider.min.css";

/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

.lookup-sku .ng-autocomplete {
  width: 100%;
}

.lookup-sku .ng-autocomplete .autocomplete-container {
  box-shadow: none;
  height    : auto;
}

.lookup-sku .ng-autocomplete .autocomplete-container input {
  width        : 100%;
  border       : 1px solid rgba(34, 35, 36, 0.11);
  display      : inline-block;
  border-radius: 20px;
  color        : rgba(112, 112, 112, 0.63);
  height       : 40px;
  line-height  : 35px;
  padding      : 0 40px 0 15px;
  font-size    : 14px;
}

.page-loader {
  position: fixed;
  overflow: hidden;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-image: url("https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING/ajax-loader.gif");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.6;
}
.tooltip {font-size: 13px;font-weight: 400;font-family: "Montserrat", sans-serif;}
/*========================= price-slider Section CSS Start =========================*/
.price-slider {margin-bottom: 40px;margin-top: 25px;}
.price-slider .noUi-horizontal {height: 12px;width: 100%;margin: 0;padding: 0;background: #E4E4E4;position: relative;border-radius: 20px;border: none;}
.price-slider .noUi-horizontal .noUi-connects {border-radius: 20px;}
.price-slider .noUi-horizontal .noUi-origin .noUi-handle:after,
.price-slider .noUi-horizontal .noUi-origin .noUi-handle:before {display: none;}
.price-slider .noUi-horizontal .noUi-connects .noUi-connect {background: #be9639;}
.price-slider .noUi-horizontal .noUi-handle {width: 18px;height: 18px;border-radius: 50%;border: 2px solid #fff;background: #be9639;box-shadow: none;top: -4px;right: -16px;padding: 0;cursor: pointer;}
.price-slider .noUi-horizontal .noUi-handle .noUi-tooltip {bottom: -50px;border-radius: 8px;background: #ffffff;width: auto;padding: 8px 11px;font-weight: 400;color: #222324;line-height: 18px;letter-spacing: .5px;font-size: 14px;opacity: 1;}
.price-slider .noUi-horizontal .noUi-handle .noUi-tooltip:before {top: -11px;left: calc(50% - 9px);border-left: 10px solid transparent;border-right: 10px solid transparent;border-bottom: 11px solid #fff;position: absolute;content: "";}
.price-slider .noUi-horizontal .noUi-handle .noUi-tooltip:after {top: -10px;left: calc(50% - 8px);border-left: 9px solid transparent;border-right: 9px solid transparent;border-bottom: 10px solid #fff;position: absolute;content: "";}
.price-slider .noUi-horizontal .noUi-handle .noUi-tooltip span.slider-tooltip-custom {color: #be9639;font-weight: 600;}
.price-slider .noUi-horizontal .noUi-pips-horizontal .noUi-marker-normal,
.price-slider .noUi-horizontal .noUi-pips-horizontal .noUi-marker-large {display: none;}
.price-slider .noUi-horizontal .noUi-pips-horizontal .noUi-value-large {font-size: 14px;color: #ffffff;font-weight: 600;letter-spacing: 1px;line-height: 18px;transform: translate(-50%,20%);position: absolute;top: -19px;}
.price-slider .noUi-horizontal .noUi-pips-horizontal .noUi-value[data-value="0"] {padding: 0 35px 0 0;color: #fff;}
.price-slider .noUi-horizontal .noUi-pips-horizontal .noUi-value[data-value="100"] {padding: 0 0 0 54px;}
.price-slider .noUi-horizontal.slider-custom-class .noUi-handle {right: -3px;}
@media screen and (max-width: 767px) {
  .price-slider .noUi-horizontal .noUi-handle .noUi-tooltip {font-size: 12px;}
}
@media screen and (max-width: 767px) {
  .price-slider .noUi-horizontal .noUi-handle .noUi-tooltip {flex-direction: column;bottom: -66px;display: flex;padding: 5px;}
}
.price-slider.applied-credit-modal .noUi-horizontal .noUi-handle .noUi-tooltip {bottom: -37px;border-radius: 4px;padding: 2px 5px;font-weight: 600;font-size: 10px;}
/*========================= price-slider Section CSS End =========================*/

/*========================= ng-select CSS Start =========================*/
.ng-select {padding: 0;border: none !important;}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {border-color: #e8ebed;}
.ng-select.ng-select-single .ng-select-container {font-weight: 400;}
.ng-select .ng-arrow-wrapper {cursor: pointer;position: relative;text-align: center;-webkit-user-select: none;user-select: none;}
.ng-select > .ng-select-container .ng-arrow {top: -20px;border-width: 0;border: 0;left: 8px;}
.ng-select .ng-arrow-wrapper .ng-arrow {border-color: transparent transparent;border-style: none;border-width: 0;pointer-events: none;display: inline-block;height: 0;width: 0;position: relative;}
.ng-select > .ng-select-container .ng-arrow:after {content: "\e906";font-family: "icomoon"!important;float: right;font-size: 14px;cursor: pointer;font-weight: 400;}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow:after {content: "\e933";top: -16px;position: relative;}
.option-detail .ng-select.ng-select-single .ng-select-container {margin-bottom: 0;border: none;min-width: 150px;}
.option-item-box .option-detail .ng-dropdown-panel.ng-select-bottom {min-width: 230px;}
.ng-select .ng-clear-wrapper {width: 16px !important;height: 29px;}
.ng-select .ng-clear-wrapper .ng-clear {font-size: 27px !important;}
.ng-select .ng-clear-wrapper:hover .ng-clear {color: #222324;}
/*========================= ng-select CSS End =========================*/

/*========================= popover CSS Start =========================*/
.popover {margin-top: 15px;max-width: 300px;min-width: 300px;box-shadow: 0 0 3px #00000029;border: none;border-radius: 10px;z-index: 1;}
.bs-popover-auto[x-placement^=bottom]>.arrow:before, .popover>.arrow:before {border-bottom-color: #00000029;}
.popover .popover-header {background-color: #fff;padding: 7px 16px;border-radius: 10px 10px 0 0;}
.popover .popover-header .pop-header-main {display: flex;align-items: center;justify-content: space-between;-webkit-justify-content: space-between;}
.popover .popover-header .pop-header-main .pop-title-right {cursor: pointer;}
.popover .popover-header .pop-header-main .pop-title-right .fa.fa-times {background: rgb(212 226 219/9%);padding: 8px;border-radius: 8px;width: 32px;height: 32px;text-align: center;font-weight: 400;}
.popover .popover-header .pop-header-main .pop-title-right .fa-times:before {content: "\f00d"!important;color: #222324;}
.popover .popover-header .pop-header-main .pop-title-left {font-size: 16px;font-weight: 600;line-height: 20px;color: #222324;}
.popover .popover-header .pop-header-main .pop-title-right {cursor: pointer;}
.popover-body {padding: 12px!important;}
.popover .popover-body .popover-body-inner {font-size: 14px;line-height: 16px;font-weight: 600;font-family: Montserrat,sans-serif;color: #222324;opacity: 1;}
.popover .popover-body .popover-body-inner .input-group .form-control {border: 1px solid #ebebeb;border-radius: 5px;text-align: left;float: left;margin: 10px 0 30px;width: 100%;font-size: 14px;line-height: 30px;font-weight: 400;color: #222324;font-family: Montserrat,sans-serif;box-shadow: none;outline: none;}
.popover .popover-body .pop-footer.text-center {margin-bottom: 0;}
.popover .pop-footer .submit-btn-grey {font-size: 14px;line-height: 19px;font-weight: 600;padding: 9px 33px;border-radius: 50px;background: #222324;color: #fff;border: 2px solid transparent;transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
/*========================= popover CSS End =========================*/

/*========================= Toast Message CSS Start =========================*/
#toast-container .ngx-toastr {font-family: Montserrat,sans-serif;box-shadow: none;position: relative;padding: 10px 35px 10px 50px;font-size: 14px;line-height: 20px;margin: 0 0 10px;width: auto;min-width: 270px;border-radius: 300px;max-width: 100%;}
#toast-container .toast-error {background-color: rgba(179,36,29,.92);}
#toast-container .ngx-toastr button {outline: none;font-weight: 300;font-size: 30px;height: 30px;line-height: 30px;top: 0;bottom: 5px;margin: auto;float: none;position: absolute;right: 10px;left: auto;color: #fff;opacity: 1;transition: all .25s ease 0s;}
/*========================= Toast Message CSS End =========================*/
