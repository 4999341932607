body { padding: 0; margin: 0 auto; font-size:14px; font-weight: normal; line-height: normal; color: #222324; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; font-family: "Montserrat", sans-serif; font-display: swap;}
* { font-family: "Montserrat", sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;font-display: swap;}
img { max-width: 100%; }
a:hover, a:focus, a:visited { outline: none; text-decoration: none }
a:hover { color: #222324 }
ul { margin: 0; padding: 0; list-style-type: none }
h1, h2, h3, h4, h5, h6, p { margin: 0 }
h1, h2, h3, h4, h5, h6 { font-weight: normal }
.btn.focus, .btn:focus {outline: none;box-shadow: none;}
main { padding-top: 56px; min-height: 900px }
footer {z-index: 33; position: relative;clear: both;}
.fa-info:before {content: "\f129";}
.loader-parent {position: relative;}
.loader-parent .loader-custom {position: absolute !important;}
.container-fluid {padding: 0 25px;}
.main_wrapper { margin: 0 auto; width: 100% }
.white-bordered-btn { background: transparent; text-align: center; border-radius: 35px; font-size: 14px; font-weight: 600; padding: 7px 20px; color: #fff; border: 2px solid #fff }
.white-bordered-btn:hover { background: #fff; color: #222324; border-color: #fff }
.explore-box h4 { font-size: 20px; line-height: 24px; color: #fff; text-align: center; font-weight: 600; margin-bottom: 4px; letter-spacing: 1px }
.explore-box h3 { font-family: 'Lexend Deca', sans-serif; color: #fff; font-size: 70px; line-height: 88px; font-weight: 400 }
.page-banner-section { -webkit-align-items: center; align-items: center; min-height: 555px; -webkit-justify-content: center; justify-content: center; background-size: cover; background-position: top center; background-repeat: no-repeat; margin-top: -56px; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex }
.page-banner-title { line-height: 90px; font-family: 'Lexend Deca', sans-serif; font-size: 70px; color: #fff; text-align: center }
.page-banner-title span { line-height: 90px; font-family: 'Lexend Deca', sans-serif; font-size: 70px; color: #fff; text-align: left }
.earn-value { text-align: center; margin-top: 25px }
.earn-value .earn-value-title { color: #fff; margin-bottom: 35px; font-size: 14px; text-transform: uppercase; font-weight: 500; line-height: 18px; letter-spacing: 0.7px }
.earn-value p { font-size: 14px; color: #fff; font-weight: 500; line-height: 18px; max-width: 300px; margin: 0 auto; letter-spacing: 0.7px }
.earn-value .earn-value-title span { font-size: 30px; font-family: 'Lexend Deca', sans-serif; vertical-align: sub; line-height: 40px; text-transform: none; letter-spacing: 0 }
.service-list { -webkit-align-items: center; align-items: center; -webkit-justify-content: center; justify-content: center; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex }
.gather-credit-title { text-align: center; font-size: 50px; line-height: 65px; color: #fff; font-family: 'Lexend Deca', sans-serif; margin-bottom: 21px }
.pricing-page-banner .page-banner-title { font-size: 50px; line-height: 56px; margin-top: 56px; min-height: 63px }
.pricing-page-banner .page-banner-title span { font-size: 50px; line-height: 56px; margin-top: 56px; min-height: 63px }
.page-banner-section .smiles-logo img { width: 268px }
.explore { padding: 0 15px }
.surface-explore, .decore-explore { background-size: cover; background-position: top center; -webkit-align-items: center; align-items: center; -webkit-justify-content: center; justify-content: center; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; min-height: 350px; position: relative;transition: all 0.35s;}
.surface-explore:hover, .decore-explore:hover {opacity: 85%;}
.surface-explore { background-image: url("https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING/flooring-banner.webp");width: auto;height: auto; background-position: center;}
.decore-explore { background-image: url("https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING/walling-banner.webp");width: auto;height: auto;background-position: center;}
.surface-explore::before,.decore-explore::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, .6) 25%, rgba(0, 0, 0, 0) 100%);
}
.none-fixed-content:not(.explore-expanded) .explore-box span { font-size: 16px; line-height: 19px; letter-spacing: 0.8px; margin-bottom: 3px }
.none-fixed-content:not(.explore-expanded) .explore-box h3 { font-size: 50px; line-height: 63px }
.surface-decore-section .surface-and-decore-hover:before { background: rgba(0, 0, 0, 0.5);}
.surface-decore-section .surface-and-decore-hover .explore-box span {display: block; top: -30px; right: auto; opacity: 1; transform: translate(-50%, 0); margin: 0px auto; transition: all 0.6s ease-in-out 0s; left: 50%; font-family: "Lexend Deca", sans-serif; color: rgb(255, 255, 255); font-size: 16px; line-height: 19px; font-weight: 400; border-bottom: 0px solid rgb(255, 255, 255); width: auto; position: absolute;}
.surface-decore-section .surface-and-decore-hover:hover .explore-box span { transform: translate(-50%, 0); opacity: 1 }
.surface-decore-section .surface-and-decore-hover .explore-box h3 { text-align: center; font-size: 40px; line-height: 50px; color: #fff; font-weight: 700; font-family: "Montserrat", sans-serif; letter-spacing: 2px }
.surface-decore-section .surface-and-decore-hover .explore-box { width: 100%; position: relative; }
.section-title { font-size: 50px; color: #fff; font-family: 'Lexend Deca', sans-serif; line-height: 60px; margin-bottom: 28px }
.section-description { font-size: 16px; color: #fff; line-height: 24px; max-width: 433px }
/*!*============================ Home Service List Section Slider ============================  *!*/
.swiper-pagination-bullet { width: 10px; height: 10px; background: transparent; opacity: 1; border: 2px solid #fff; margin: 0 5px; }
.swiper-pagination-bullet.swiper-pagination-bullet-active { background: #ffffff; border-color: #ffffff; }
/*!*============================ Home Service List Section Slider ============================  *!*/

/*!*============================ Home Product Slider ============================  *!*/
.product-slider-navigation { -webkit-align-items: center; align-items: center; -webkit-justify-content: center; justify-content: center; margin-top: 0; position: absolute; top: 10px; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); left: 67.5vw; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;font-size: 25px;}
.product-slider-navigation #product-left,
.product-slider-navigation #product-right,
.client-slider-arrow { cursor: pointer; }
.navigation-pagination-container { position: relative; margin-top: 15px; min-height: 25px; }
.product-pagination { line-height: 0; margin-top: -4px;}
.product-pagination .swiper-pagination-bullet { background: transparent; border: 1px solid #222324; margin: 0 3px;}
.product-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active { background: #222324;border: 1px solid #222324;}
/*!*============================ Home Product Slider ============================  *!*/

/*!* ================== Gatherco Credit Section Css ==================*!*/
.two-shade-section.gatherco-credit-section { background: #f5efea }
.two-shade-section { display: flex; width: 100%; position: relative; z-index: 1 }
.two-shade-left { width: 50%; display: flex; align-items: center }
.two-shade-left button { text-align: center; border-radius: 27px; font-size: 16px; font-weight: 600; padding: 8px 15px 7px; color: #40554a; border: 2px solid #40554a; line-height: 24px; min-width: 117px; height: 43px }
.two-shade-left button:hover { background: #40554a; color: #fff; border-color: #40554a }
.two-shade-content { padding: 80px 50px 80px 184px; width: 100% }
.earn-value-content .grey-bordered-btn {background: transparent;border: 2px solid #40554a;height: 42px;display: flex;align-items: center;max-width: 126px;justify-content: center;overflow: hidden;}
.earn-value-content label { font-size: 50px; line-height: 60px; color: #222324; margin-bottom: 35px; font-family: "Lexend Deca" }
.plant-image img { max-height: 249px }
.earn-value-content p.signin-text-first { margin-bottom: 30px;}
.earn-value-content p { font-size: 16px; line-height: 28px; margin-bottom: 54px; max-width: 465px }
.home-gatherco-credit-wrap .earn-value-content p { font-size: 20px;}
.two-shade-right { width: 50%; background: #40554a; -webkit-align-items: center; -webkit-justify-content: center; align-items: center; justify-content: center; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex }
.gatherco-credit-section .bg-transparent .two-shade-content { padding-top: 66px; padding-bottom: 66px }
.plant-image { text-align: center }
.bg-transparent .plant-image img { max-height: 325px }
/*!* ================== Gatherco Credit Section Css ==================*!*/
.project-detail-title {font-size: 50px;font-family: Lexend Deca;line-height: 65px;margin-bottom: 14px;text-align: center}
.section-inner-container {margin: 0 auto;max-width: 100%;padding: 0 35px;overflow: hidden;word-break: break-word;}
.project-detail-right p a,
.service-include-widget p a {color: #be9639!important;}
.project-detail-right p {font-size: 16px;color: #222324;line-height: 24px;margin-bottom: 24px;}
.contact-form-section.contact-us-container .section-inner-container {max-width: 1424px;padding: 0 15px;}
.form-control {border: none;}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {opacity: 0;}
.form-control:focus {outline: none;box-shadow: none;}
.form-control.is-invalid, .was-validated .form-control:invalid {background-image: none}
.form-control.is-valid,
.was-validated .form-control:valid {background: none;}
.form-control.is-valid:focus, .was-validated .form-control:valid:focus,
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {border-color: #707070;box-shadow: none;}
.form-control::placeholder {color: rgb(34 35 36 / 70%);opacity: 1;font-weight: 400;}
.fixedscroll-section .faq-section {word-break: break-word;padding: 40px;background: #fff;margin: 0 0 -45px;}
.faq-section {background: #fff;word-break: break-word;margin: 0;padding: 70px 35px 100px;}
.download-article-btn-mobile { display: none }
.deposit-payment-due-status .order-progress-item label, .required-field, .text-red {color: #d90008;}
.required-field {font-size: 14px;font-weight: 600;}
/*============================ Resource Hub Page  search form ng select ============================  */
.respurce-search-form .resource-form .is-valid .ng-select-container { background: #fff ; border-radius: 10px; border-bottom: 0; padding: 0 20px; margin-bottom: 0 }
.respurce-search-form .resource-form .is-valid .ng-select-container .ng-placeholder { line-height: 36px; padding-left: 0; color: #444; font-size: 14px; font-weight: 500; font-family: "Montserrat", sans-serif; text-transform: uppercase }
.respurce-search-form .resource-form .is-valid .ng-select-container .ng-value .ng-value-label { line-height: 36px !important; padding-left: 0; color: #222324 !important; font-size: 14px !important; font-weight: 500 !important; font-family: "Montserrat", sans-serif }
.respurce-search-form .resource-form .is-valid .ng-select-container input { padding-left: 20px !important }
.respurce-search-form .resource-form .is-valid.ng-select-opened .ng-select-container { border-radius: 10px 10px 0 0 }
.respurce-search-form .resource-form .is-valid.ng-select-opened .ng-dropdown-panel.ng-select-bottom { margin-top: 1px; border-radius: 0 0 10px 10px }
.respurce-search-form .resource-form .is-valid.ng-select-opened .ng-dropdown-panel.ng-select-bottom .ng-option { padding: 4px 20px; font-size: 14px; color: #222324 !important; font-weight: 400 }
.respurce-search-form .resource-form .is-valid.ng-select-opened .ng-dropdown-panel.ng-select-bottom .scroll-host { padding-bottom: 15px; border-radius: 0 0 10px 10px; max-height: 170px }
.respurce-search-form .resource-form .is-valid.ng-select-opened .ng-dropdown-panel.ng-select-bottom .ng-option-disabled { padding-top: 15px }
.resource-form-field .ng-select .ng-clear-wrapper {right: -2px; }
.resource-form-field .ng-select .ng-clear-wrapper .ng-clear {font-size: 25px; top: 0;}
.search-resource-list .card-footer {padding: 10px 15px 10px 15px!important;}
.search-resource-list .card-footer .form-control {width: auto;}
.btn-primary:not(:disabled):not(.disabled):active {box-shadow: none;outline: none;color: #fff;background-color: #222324;border-color: #222324;}
.btn-primary:not(:disabled):not(.disabled):active:focus {box-shadow: none;outline: none;}

/*============================ Resource Hub Page  search form ng select ============================  */
.ng-select.ng-select-single .ng-select-container { height: 36px; padding-top: 0;  padding-left: 0; padding-bottom: 0;  border: 0;  border-bottom: 1px solid #e8ebed; border-radius: 0; font-size: 13px; color: #222324; line-height: 30px; font-weight: 500; min-height: auto; appearance: none; -moz-appearance: none; -webkit-appearance: none; box-shadow: none !important; background-color: transparent; margin-bottom: 15px}
.form-control { min-height: 44px; border: none; border-radius: 5px; margin-bottom: 15px; color: #222324; font-weight: 500 }
.ng-select .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {padding-left: 0;}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow { top: -1px !important }
.ng-select .ng-arrow-wrapper { padding-right: 0 }
.ng-select .ng-clear-wrapper { text-align: center; color: #222324 }
.ng-select .ng-clear-wrapper .ng-clear { font-size: 20px; line-height: 0.5; top: 1px; position: relative }
.ng-dropdown-panel.ng-select-bottom { margin-top: 0; box-shadow: 0px 3px 6px #00000029; border-radius: 0px 0px 7px 7px; border: 0 }
.ng-dropdown-panel .scroll-host { -ms-overflow-style: none; scrollbar-width: none }
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label { text-align: left; font: normal normal normal 13px/30px Montserrat; letter-spacing: 0px; color: #222324; opacity: 1 }
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked { color: #333; background-color: #f7f7f7 }
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked { background-color: #f7f7f7 }
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input { padding-left: 0 }
.ng-select .ng-select-container .ng-value-container { padding-left: 0 }
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .ng-value-label { font-size: 14px; color: #222324; line-height: 30px; font-weight: 500 }
.delivery-address .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .ng-value-label {font-weight: 400;}
.ng-select.ng-select-disabled {background: rgb(34 35 36/7%);padding-top: 0;border-radius: 5px !important;cursor: not-allowed;}
.ng-select.ng-select-single.ng-select-disabled .ng-select-container {border: none;padding-left: 10px;cursor: not-allowed;}
.ng-select.ng-select-single.ng-select-disabled .ng-select-container .ng-arrow-wrapper {display: none;}
.ng-select.ng-select-single.ng-select-disabled .ng-select-container .ng-value-container .ng-value .ng-value-label {font-size: 14px;color: #222324b3;line-height: 30px;font-weight: 500;}
/* ================== Looking For More Modal Css ==================*/
.looking-for-more .close { position: absolute; top: 0; right: 0; margin: 10px 27px 10px 15px; padding: 5px; box-shadow: none; outline: none; color: #000000; z-index: 999; font-size: 31px; }
.looking-for-more .modal-dialog .modal-content { border-radius: 36px; }
.looking-for-more .modal-dialog { transform: none; max-width: 361px; margin: 0 auto; top: 20%; transform: translate(0, -40%); }
.looking-for-more .modal-dialog { max-width: 1166px; max-height: 476px; }
.looking-for-more .modal-dialog .modal-content .col-md-4 { width: 34%; margin: 0; padding: 0; }
.looking-for-more .modal-dialog .modal-content .col-md-8 { width: 66%; margin: 0; padding: 0; }
.looking-for-more .modal-dialog .modal-content .content h2 { width: 100%; margin: 84px 0 0 42px; font-size: 40px; line-height: 44px; color: #000000; font-weight: 400; font-family: "Lexend Deca", sans-serif; }
.looking-for-more .modal-dialog .modal-content .content p { width: 100%; margin: 30px 0 0 42px; padding: 0 80px 0 0px; font-size: 14px; line-height: 20px; color: #000000; font-weight: 500; font-family: "Lexend Deca", sans-serif; }
.looking-for-more .modal-dialog .modal-content .content button.btn.btn-default { margin: 34px 0 0 42px; border: 1px solid #222324; border-radius: 27px; box-shadow: none; }
.looking-for-more .modal-dialog .modal-content .content button.btn.btn-default { margin: 34px 0 0 42px; padding: 13px 28px; border: 1px solid #40554a; border-radius: 27px; box-shadow: none; font-size: 16px; line-height: 24px; font-weight: bold; font-family: "Montserrat", sans-serif; }
.looking-body { min-height: 476px; }
.noselect { -webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
@media screen and (min-width:320px) and (max-width:767px) {
  .looking-for-more .modal-dialog { max-width: 95%; }
  .looking-body { width: 100%; margin: 0; }
  .looking-for-more .modal-dialog .modal-content .col-md-4 { width: 100%; margin: 0  0 40px 0; order: 1; }
  .looking-for-more .modal-dialog .modal-content .content h2 { width: 100%; margin: 84px 0 0 15px; max-width: 350px; }
  .looking-for-more .modal-dialog .modal-content .content p { width: 100%; margin: 30px 0 0 15px; }
  .looking-for-more .modal-dialog .modal-content .col-md-8 { width: 100%; margin: 0; padding: 0; order: 0; }
  .looking-for-more .modal-dialog .modal-content .content button.btn.btn-default { margin: 34px 0 0 15px; }
}
@media screen and (min-width:768px) and (max-width:1200px) {
  .looking-for-more .modal-dialog .modal-content .content button.btn.btn-default { margin-bottom: 34px; }
  .looking-for-more .modal-dialog { max-width: 95%; }
  .looking-body { width: 100%; margin: 0; }
}
/* ================== Looking For More Modal Css ==================*/

/*=================================== product list swiper slider CSS Start ===================================*/
.product-list-type {color: #222324;font-size: 26px;line-height: 33px;font-family: Lexend Deca,sans-serif;}
.product-list-carousel {position: relative;}
.product-list-carousel .swiper-container {overflow: visible;width: 100%;}
.product-list-carousel .swiper-wrapper {margin: 0;}
.product-list-carousel .swiper-wrapper .swiper-slide {padding: 0;transform: scale(1);transition: transform .2s ease-in-out;transform-origin: center left;}
.product-item {box-shadow: 0 0 16px rgba(0,0,0,.07);margin: 40px 0;border-radius: 5px;}
.product-item-image {position: relative;}
.product-item-image .img-wrapper {position: relative;padding-bottom: 100%;width: 100%;overflow: hidden;}
/*.product-item-image .img-wrapper img {position: absolute;top: 0;left: 0;width: 100%;height: auto;border: 0;-o-object-fit: cover;object-fit: cover;border-radius: 5px 5px 0 0;}*/
.product-item-image .img-wrapper img {position: absolute;top: 0;left: 0;border: 0;-o-object-fit: cover;object-fit: cover;border-radius: 5px 5px 0 0;height: auto;}
.product-color-palette {position: absolute;bottom: 10px;left: 17px;max-height: calc(100% - 15px);display: flex;overflow-y: visible;font-size: 0;padding-right: 45px;-ms-overflow-style: none;scrollbar-width: none;z-index: 9;}
.product-item .product-item-image .product-color-palette {width: 100%;flex-wrap: wrap;}
.product-color-palette ul {height: 46px;display: flex;width: auto;}
.product-color-palette ul li {cursor: pointer;transition: all .5s ease;display: inline-block; margin-right: 10px;margin-top: 10px;font-size: 14px;}
.product-color-palette ul li .color-palette-thumb {background: #ffffff;border-radius: 20px;overflow: hidden;transition: .15s ease-in-out;display: flex;align-items: center;gap: 0;height: 30px;}
.product-color-palette ul li .color-palette-thumb.active {background: #222324;}
.product-color-palette ul li .color-palette-thumb.active .color-palette-name-inner {color: #ffffff;}
.product-color-palette ul li .color-palette-thumb a {position: relative;width: 30px;height: 30px;display: inline-block;vertical-align: middle;transition: .15s ease-in-out;}
.color-palette-thumb a img, .color-thumb a img {width: 30px;height: 30px;border-radius: 30px;border: 2px solid #fff;background-size: cover;background-position: 50%;position: relative;}
.product-color-palette ul li .color-palette-thumb a.active img {border-color: #222324;}
.product-color-palette ul li .color-palette-thumb:hover {gap: 5px;}
.product-color-palette ul li .color-palette-thumb:hover .color-palette-name{visibility: visible;opacity: 1;width: auto;}
.product-item:hover .color-palette-thumb.active {gap: 5px;}
.product-item:hover .color-palette-thumb.active .color-palette-name { visibility: visible;opacity: 1;width: auto}
.color-palette-name-inner {height: 30px;display: inline-block;font-size: 12px;line-height: 30px;cursor: pointer;padding: 0 10px 0 0;white-space: pre;}
.product-label {position: absolute;right: -15px;top: 10px;width: 50px;overflow: hidden;height: 382px;}
.product-label li+li {margin-top: 5px;}
.product-item-detail {background: #fff;padding: 10px;border-radius: 0 0 5px 5px;align-items: center;justify-content: space-between;display: flex;}
.product-detail-left h4 {font-size: 14px;line-height: 21px;font-weight: 700;color: #222324;margin-bottom: 4px;}
.product-item-detail p.product-category {color: #212529;font-size: 12px;line-height: 14px;}
.product-list-carousel .scaleActive .product-block { padding: 0 15px; transform: scale(1); transition: transform 0.2s ease-in-out; transform-origin: center left;}
.product-list-carousel .swiper-wrapper .swiper-slide.scaling { transform: scale(1.1); transition: 300ms all }
.product-list-carousel .swiper-wrapper .swiper-slide.scaling ~ .swiper-slide { transform: translate3d(2vw, 0, 0); transition: 300ms all }
.product-list-carousel .swiper-wrapper .swiper-slide.scaling .product-item-detail .product-detail-right a { opacity: 1; visibility: visible; transition: 0.9s opacity ease-in-out }
.color-palette-name {visibility: hidden;opacity: 0;transition: visibility 0s, opacity 0.5s linear;width: 0;}
.dropdown-toggle:after {display: none}
.product-detail-inner.show {border-radius: 8px;background: #fff;box-shadow: 0 6px 12px rgb(0 0 0/6%);}
.product-detail-inner .sport-option {display: inline-block;margin: 0;padding: 0;border: 0;height: 50px;width: 50px;}
.product-detail-inner .dropdown-menu {padding: 0;margin: 0;border: 0;width: 50px;min-width: 50px;top: auto!important;bottom: 100%!important;transform: translateY(20px)!important;transition: all .4s ease 0s;opacity: 0;visibility: visible;display: block;}
.product-detail-inner .dropdown-menu.show {opacity: 1;border-radius: .5rem;}
.product-detail-inner .dropdown-menu .sport-menu ul {padding: 6px 0;display: inline-block;width: 100%;margin: 0;}
.product-detail-inner .dropdown-menu .sport-menu ul li {width: 100%;display: block;margin: 0 0 6px;padding: 0;text-align: center;}
.product-detail-inner .dropdown-menu .sport-menu ul li .favourite-btn {width: 34px;height: 34px;display: inline-block;text-align: center;margin: 0 auto;line-height: 33px;border-radius: 8px; cursor: pointer}
.product-detail-inner .dropdown-menu .sport-menu ul li .favourite-btn:hover {background-color: #e8ebed;}
.product-detail-inner .dropdown-menu .sport-menu ul li .favourite-btn .icon-heart:before {background: url('https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING/border_heart.svg') no-repeat 50%;content: "";background-size: 17px auto;line-height: normal;display: inline-block;margin: 0;padding: 0;width: 22px;height: 22px;vertical-align: middle;}
.product-detail-inner .dropdown-menu .sport-menu ul li .favourite-btn .icon-play:before {background: url('https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING/gallery_image.svg') no-repeat 50%;content: "";width: 20px;height: 22px;display: inline-block;background-size: 18px auto;vertical-align: middle;}
.product-list-navigation-arrow {position: absolute;top: 50%;transform: translateY(-50%);z-index: 9;box-shadow: 0 0 4px rgba(0,0,0,.14);background: hsla(0,0%,100%,.8);width: 39px;height: 39px;text-align: center;border-radius: 50%;line-height: 39px;cursor: pointer;outline: none;margin-top: -30px;}
.product-list-navigation-arrow span {font-size: 25px;line-height: 39px;}
.product-list-next {left: auto;right: -20px;}
.product-list-prev {right: auto;left: 20px;}
.swiper-button-disabled {display: none;}
.product-gallery .show-in-mobile {display: none;}
.product-gallery {margin-bottom: 40px;}
.align-items-center {align-items: center;}
.product-gallery-images {padding: 0 15px;width: 543px;}
.product-gallery-main {overflow: hidden;border-radius: 4px;position: relative;}
.product-gallery-text {width: calc(100% - 543px);float: left;margin: 0;padding: 0 15px;}
.product-gallery-detail {max-width: 350px;margin: 0 0 0 40px;}
.brand-logo {text-align: center;margin-bottom: 34px;}
.product-gallery-detail .product-details {padding: 0;margin-bottom: 40px;}
.product-details h5 {color: #222324;font-size: 16px;line-height: 21px;font-weight: 700;margin-bottom: 12px;}
.product-details p {font-size: 16px;font-weight: 400;line-height: 21px;}
.product-color-palette-list ul {margin: 0 -5px;}
.product-color-palette-list ul li {display: inline-block;padding: 5px;}
.product-color-palette-list ul li .color-palette-thumb {border-radius: 20px;overflow: hidden;border: 1px solid #ccc;transition: .15s ease-in-out;font-weight: 700;}
.product-color-palette-list ul li .color-palette-thumb a {position: relative;width: 30px;height: 30px;display: inline-block;vertical-align: middle;transition: .15s ease-in-out;}
.product-gallery .color-palette-thumb a img, .product-gallery .color-thumb a img {border: none}
.product-color-palette-list ul li .color-palette-thumb .color-palette-name {height: 30px;display: inline-block;color: #fff;line-height: 28px;border-radius: 20px;vertical-align: middle;width: 0;transition: .15s ease-in-out;}
.product-color-palette-list ul li .color-palette-thumb:hover .color-palette-name {padding: 0 10px 0 6px;width: auto;color: #222324;transition: .15s ease-in-out;visibility: visible;opacity: 1;}
.product-gallery-options {text-align: center;margin-top: 33px;font-size: 0;}
.product-gallery-options .btn {min-width: 117px;font-size: 16px;line-height: 24px;margin: 0;height: auto;padding: 8px 10px 7px;font-weight: 600;letter-spacing: -.16px; text-align: center;border-radius: 35px;border-color: #222324;background: #222324;border-width: 2px;color: #ffffff}
.product-gallery-options .btn:hover {background: #ffffff;color: #222324;}
.product-gallery-options .btn.transparent-bg-btn {border: 2px solid transparent;min-width: 143px;margin-left: 13px;background: transparent;color: #222324;}
.product-gallery-options .btn.transparent-bg-btn:hover {background: #e8ebed;color: #222324;}
.product-gallery-options .btn .icon-heart {margin-right: 9px;font-size: 19px;position: relative;top: 0px;}
.product-gallery-options .btn.transparent-bg-btn .icon-heart:before {background: url('https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING/border_heart.svg') no-repeat center;content: "";background-size: 18px auto;vertical-align: middle;line-height: normal;display: inline-block;margin: 0;padding: 0;width: 22px;height: 22px;}
.product-gallery-pagination {position: absolute;bottom: 20px!important;z-index: 1;left: 0;text-align: center;width: 100%;font-size: 0;}
.swiper-pagination-bullet {width: 10px;height: 10px;background: transparent;opacity: 1;border: 2px solid #fff;margin: 0 5px;cursor:pointer;}
.swiper-pagination-bullet.swiper-pagination-bullet-active {background: #fff;border-color: #fff;}
@media screen and (max-width: 1199px) {
  .product-color-palette ul li .color-palette-thumb,
  .product-color-palette ul li .color-palette-thumb a {width: 24px;height: 24px;}
}
/*=================================== product list swiper slider CSS End ===================================*/

/*.additional-formats .scaleActive .product-block { padding: 0 15px; transform: scale(1); transition: transform 0.2s ease-in-out; transform-origin: center left;}*/
/*.additional-formats .swiper-wrapper .swiper-slide.scaling { transform: scale(1.1); transition: 300ms all }*/
/*.additional-formats .swiper-wrapper .swiper-slide.scaling ~ .swiper-slide { transform: translate3d(2vw, 0, 0); transition: 300ms all }*/
/*.additional-formats .swiper-wrapper .swiper-slide.scaling .product-item-detail .product-detail-right a { opacity: 1; visibility: visible; transition: 0.9s opacity ease-in-out }*/

/* ================== Minimum Amount modal  ================== */
.modal-header .icon-close { font-size: 12px; color: #aca7a7 }
.modal-header .icon-close:hover { color: #868889 }
.minimumAmount .modal-header .close { opacity: 1; padding: 0; margin: 0; line-height: 0; margin-left: auto; outline: none; -webkit-transition: all 0.35s ease 0s; transition: all 0.35s ease 0s; z-index: 99 }
.minimumAmount .modal-header .close:hover .icon-close { color: #868889 }
.minimumAmount .modal-header { border: none }
.minimumAmount .modal-footer { border: none; padding: 15px 15px 25px 15px }

.minimumAmount .modal-dialog { max-width: 680px }
.minimumAmount .modal-footer { text-align: center; -webkit-justify-content: center; justify-content: center; border-top: 1px solid #e8ebed }
.minimumAmount .modal-footer .btn { height: 38px; width: 137px; margin: 0; line-height: 0px }
.minimumAmount .modal-footer .btn.grey-bg-btn:hover { background: rgba(34, 35, 36, 0.8); color: #fff; border-color: transparent }
.title-lexend-28 { font-size: 28px; line-height: 20px; font-family: "Lexend Deca" }
.text-mont-16 { color: #222324; font-size: 16px; line-height: 20px }

.imageEnlarge { background: rgba(0, 0, 0, 0.6) }
.imageEnlarge .modal-dialog { max-width: 100% }
.imageEnlarge .modal-dialog .modal-content { background: transparent; box-shadow: none; }
.imageEnlarge .product-list-navigation .product-prev-image { right: auto; left: 10px }
.imageEnlarge .product-list-navigation .product-next-image { right: 15px; left: auto }
.imageEnlarge .product-list-navigation-arrow { background: #000; color: #fff; margin: 0 }
.imageEnlarge .product-gallery-image {height: calc(100vh - 60px); -webkit-align-items: center; align-items: center; -webkit-justify-content: center; justify-content: center; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-wrap: wrap }
.imageEnlarge .product-enlarge-gallery .product-gallery-image img { height: auto; width:100%; max-height: 100%; max-width: 100%; vertical-align: middle }
.swiper-container { overflow: visible; width: 100%; }

.favourite-modal .modal-dialog { max-width: 321px }
.favourite-modal .modal-header { border-bottom: 1px solid #e8ebed; padding: 1rem; }
.favourite-modal .modal-footer { border-top: 1px solid #e8ebed; padding: 15px 15px 25px; }
.favourite-modal .modal-footer .footer-btn-grp { margin: 0; width: 100%; text-align: center }
.favourite-modal .modal-footer .footer-btn-grp .btn { min-width: 139px; margin: 0 5px; font-size: 14px; line-height: 18px; letter-spacing: -0.14px; padding: 8px 15px }
.large-modal .modal-dialog { width: 90%; max-width: 1372px }
.finishesModal .modal-dialog.modal-dialog-centered,.moreInformationModal .modal-dialog-centered {max-width: 1082px;}
.moreInformationModal .modal-header, .groutColor .modal-header, .videoPreviewModal .modal-header { -webkit-justify-content: center; justify-content: center; margin-top: 0; padding: 23px 1rem }
.moreInformationModal .modal-header { padding: 29px 15px 15px }
.moreInformationModal .modal-header .close, .groutColor .modal-header .close, .videoPreviewModal .close { position: absolute; right: 15px; top: 15px }
.videoPreviewModal .modal-dialog-centered { max-width: 1000px }
.videoPreviewModal iframe { border: none; width: 100%; height: 550px}
.seamless-texture-image-modal .modal-dialog { max-width: 1102px;}
.full-size-modal { background: #fff; padding: 0 !important }
.full-size-modal .modal-dialog { max-width: 100%; margin: 0; -webkit-align-items: flex-start; align-items: flex-start }

.thumbSlider .swiper-slide { flex-shrink: 0; position: relative; transition-property: transform; height: 100%; border-radius: 5px; border: 2px solid transparent; cursor: pointer;}
.thumbSlider .swiper-slide img { display: block; width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; border-radius: 5px; border: 2px solid transparent;}
.thumbSlider .swiper-slide.swiper-slide-thumb-active img {opacity: 1; border: 2px solid #BE9639; transition: all .35s ease-in-out;}

/* ================== Minimum Amount modal  ================== */

/* ================== ngb-modal CSS Start  ================== */
.modal-content { border: none; box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.08); border-radius: 10px; opacity: 1 }
.modal-header {border-bottom: 1px solid #e8ebed;padding: 10px}
.modal-title {width: 100%;font-size: 22px;text-align: center;font-family: Lexend Deca;font-weight: 700;}
.modal-footer {padding: 10px;border-top: 1px solid #e8ebed;}
.footer-btn-grp {margin: 0;width: 100%;display: flex;gap: 10px;justify-content: center;align-items: center;}
.quote-order-export-pdf-model .modal-dialog,
.quotes-accept-model .modal-dialog,
.payment-receipt-modal .modal-dialog,
.verification-account-modal .modal-dialog {max-width: 600px;}
.pac-container {z-index: 9999}
/* ================== ngb-modal CSS End  ================== */

/* ================== Button Css  ================== */
.btn { text-align: center; border-radius: 35px; font-size: 14px; font-weight: 600; padding: 7px 20px;}
.btn-light-secondary {font-size: 14px;font-weight: 600;line-height: 16px;color: #222324;border: 1px solid transparent;background: rgb(64 85 74/4%);border-radius: 27px;padding: 10px 24px;outline: none;box-shadow: none;transition: all .35s ease-in-out;min-width: 137px;text-align: center;}
.btn-light-secondary:hover {border: 1px solid #222324;background: #fff;color: #222324;}
.grey-bg-btn { background: #222324; text-align: center; border-radius: 35px; font-size: 14px; font-weight: 600; padding: 7px 20px; color: #fff; border-color: #222324; border-width: 2px; line-height: 24px }
.grey-bg-btn:hover { background: #ffffff; color: #222324; border-color: #222324 }
.dark-bg-btn {font-size: 14px;font-weight: 600;line-height: 16px;color: #ffffff;background: #222324;border-radius: 27px;padding: 10px 24px;}
.dark-bg-btn:hover {opacity: 70%;color: #ffffff;}
.grey-bordered-btn {min-width: 139px;background: #fff;text-align: center;border-radius: 35px;font-size: 14px;font-weight: 600;padding: 7px 14px;color: #222324;border: 2px solid #222324;line-height: 18px;}
.grey-bordered-btn:hover {background: #222324;color: #fff;border-color: #222324;}
.btn-dark-secondary {font-size: 14px;font-weight: 600;line-height: 16px;color: #fff;background: #222324;border-radius: 27px;padding: 10px 24px;outline: none;box-shadow: none;transition: all .3s ease-in-out;}
.btn-dark-secondary:hover {background: rgba(34,35,36,.8);color: #fff;}
.order-btn-box .btn.iron-bordered-btn {background: rgb(34 35 36 / 70%);color: #fff;cursor: not-allowed;}
.order-btn-box .btn.red-bordered-btn {background: #d90008;color: #ffffff;/*border: 1px solid #d90008 !important;*/}
.order-btn-box .btn.red-bordered-btn:hover {background: #d90008;color: #ffffff;}
/* ================== Button Css  ================== */

/* ==================  Dashboard all Page Css Start  ================== */
.right-content {background: #f7f7f7;width: calc(100% - 245px);margin-left: auto;}
.tab-inner-container {padding: 40px 40px 25px;min-height: calc(100vh - 56px);}
/* ==================  Dashboard all Page Css End  ================== */

/*======================= Custome_pagination CSS Start =======================*/
.card-footer{background:#fff;border:none;padding: 20px 0 0 15px !important;}
.form-control {min-height: inherit;border: none;border-radius: 5px;margin-bottom: 0;color: #222324;font-weight: 500;font-size: 14px}
.form-control:focus {box-shadow: none;outline: none;}
.align-items-center span {font-size: 14px;font-weight: 500;line-height: 19px;}
.pagination-wrap .pagination.custome_pagination {align-items: center;}
.custome_pagination.pagination .disabled {display:none;}
.custome_pagination.pagination a{border:none;padding:0;margin:0;background:transparent;}
.custome_pagination.pagination .pagination-prev a,
.custome_pagination.pagination .pagination-next a{font-size:0;position:relative;color:#666;border:1px solid transparent;background:transparent;box-shadow:none;width:35px;height:35px;line-height:35px;padding:0;border-radius:50%;text-align:center;}
.custome_pagination.pagination .pagination-prev a::before{content:"\f053";position:absolute;top:0;left:0;height:30px;width:30px;font-size:16px;font-family:'Font Awesome 5 Pro';color: #222324;font-weight: 700;}
.custome_pagination.pagination .pagination-next a::before{content:"\f054";position:absolute;top:0;left:0;height:30px;width:30px;font-size:16px;font-family:'Font Awesome 5 Pro';color: #222324;font-weight: 700;}
.custome_pagination.pagination .pagination-page a{background:transparent;border:1px solid transparent;border-radius:50%;color:#333;font-size:14px;width:30px;height:30px;line-height:normal;padding:0;display:flex;align-items:center;justify-content:center;margin:3px 5px 0 3px;box-shadow: none;outline: none;}
.custome_pagination.pagination .pagination-page.active a{border:1px solid #222324;}
select.mx-1.form-control.form-control-sm.ng-pristine.ng-valid.ng-touched {cursor: pointer;}
.sample-order-pages .card-footer ul.custome_pagination .pagination-first .page-link,
.sample-order-pages .card-footer ul.custome_pagination .pagination-last .page-link {width: 30px;font-size: 0;height: 30px;margin-top: 3px;}
.sample-order-pages .card-footer ul.custome_pagination .pagination-last .page-link {background: url('https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING/pagination-last.svg') no-repeat 50%;background-size: 14px auto;}
.sample-order-pages .card-footer ul.custome_pagination .pagination-first .page-link {background: url('https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING/pagination-first.svg') no-repeat 50%;background-size: 14px auto;}
.page-link:focus {outline: none;box-shadow: none;}
@media screen and (max-width:1200px) {
  .sample-order-pages .card-footer{width: auto!important;}
}
@media screen and (max-width:600px) {
  .sample-order-pages .card-footer{padding-left: 0 !important;}
}
/*======================= Custome_pagination CSS End =======================*/

/*============================ Slider ============================  */
.swiper-container { overflow: visible; width: 100%; }
.service-list-section-slider .swiper-pagination { bottom: 30px !important; left: 35px !important; width: unset !important; right: auto !important; }
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet { margin: 0 4px; cursor: pointer; }
.swiper-pagination-bullet { width: 10px; height: 10px; background: transparent; opacity: 1; border: 2px solid #fff; margin: 0 5px; }
.swiper-pagination-bullet.swiper-pagination-bullet-active { background: #ffffff; border-color: #ffffff; }
/*.detail-product-gallery .swiper-container .swiper-wrapper {height: 100% !important;}*/
.finish-widget.scroll-section-widget .swiper-slide{ width: 209px!important;}
/*============================ Slider ============================  */

/*============================ Title css ============================  */
.title-lexend-30 { font-size: 30px; line-height: 40px; font-family: 'Lexend Deca', sans-serif;}
.title-lexend-28 { font-size: 28px; line-height: 20px; font-family: 'Lexend Deca', sans-serif;}
.title-lexend-25 { font-size: 25px; line-height: 30px; font-family: 'Lexend Deca', sans-serif;}
.title-lexend-26 { font-size: 26px; line-height: 20px; font-family: 'Lexend Deca', sans-serif; font-weight: normal;}
.title-lexend-24 { font-size: 24px; line-height: 32px; font-family: 'Lexend Deca', sans-serif; color: #222324;}
.title-lexend-21 { font-size: 21px; line-height: 20px; font-family: 'Lexend Deca', sans-serif; color: #222324;}
.title-lexend-21 * { font-family: 'Lexend Deca', sans-serif;}
.title-lexend-20 { font-size: 20px; line-height: 25px; font-family: 'Lexend Deca', sans-serif; color: #222324;}
.text-mont-18 { font-size: 18px; line-height: 20px }
.info-table .table td .text-mont-18 { font-size: 18px; line-height: 25px; font-weight: 500; font-family: "Montserrat", sans-serif; margin-top: 10px }
.text-mont-16 { color: #222324; font-size: 16px; line-height: 20px }
.text-mont-14 { font-size: 14px; line-height: 20px }
.text-mont-13 { font-size: 13px; line-height: 21px }
/*============================ Title css ============================  */
.invalid-feedback {font-weight: 400 !important;font-size: 12px !important;text-align: left;}

/*================================= Dropdown CSS Start =================================*/
.dropdown-item:focus, .dropdown-item:hover,
.dropdown-item.active, .dropdown-item:active {color: #222324;background-color: rgb(34 35 36 / 7%);}
/*================================= Dropdown CSS End =================================*/

/*================================= Privacy Policy and Refund Policy CSS Start =================================*/
main.small-content-pages {min-height: 0 !important;}
.reward-process-section {background: #f6f5f3;padding: 100px 0;}
.section-inner-container {margin: 0 auto;max-width: 100%;padding: 0 35px;overflow: hidden;word-break: break-word;}
.reward-process-section .section-inner-container .reward-row h3 {text-align: center;margin-bottom: 35px;}
.reward-process-section .section-inner-container .reward-row p {line-height: 22px;margin-bottom: 15px;text-align: left;}

/*================================= Privacy Policy and Refund Policy CSS End =================================*/

.fa.fa-info {border: 1px solid #212529;width: 16px;height: 16px;text-align: center;border-radius: 25px;line-height: 14px;font-size: 10px;color: #222324;cursor: pointer;}
.linkclass {cursor: pointer;}
/*================================= scroll CSS Start =================================*/
::-webkit-scrollbar-track {-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);border-radius: 10px;background-color: #F5F5F5;}
::-webkit-scrollbar {width: 6px;height: 6px;background-color: #F5F5F5;}
::-webkit-scrollbar-thumb {border-radius: 10px;background-color: rgb(34 35 36 / 20%);}
/*================================= scroll CSS End =================================*/

/*========================= bs-datepicker css start ========================= */
.delivery-quotes-title .delivery-quotes-title-description {font-size: 14px;font-weight: 400;line-height: 24px;}
.theme-green .bs-datepicker-head {background-color: #222324 !important;}
.bs-datepicker-body table th {color:#222324 !important;}
.bs-datepicker-body table td {color:#222324 !important;}
.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]:after,
.theme-green .bs-datepicker-body table td[class*=select-] span:after {background-color: #222324 !important; color:white !important ; font-weight: 700;}
.theme-green .bs-datepicker-body table td span.disabled,
.theme-green .bs-datepicker-body table td.disabled span {color: rgba(0, 0, 0, 0.25);
}
/*========================= bs-datepicker css End ========================= */

/*========================= Status Css start ========================= */
.price {font-size: 14px;font-weight: 600;line-height: 19px;font-family: Montserrat,sans-serif;margin: 0;padding: 0;color: #222324;}
.price.text-green {color: #499d63 !important;}
.crates {font-size: 12px;font-weight: 600;line-height: 18px;color: #499d63;text-align: right;float: right;}
.price.text-green .fa.fa-info {border: 1px solid #499d63;color: #499d63;}
.price.quote-has-been-accepted-status {color: #d90008 !important;}
.price.quote-has-been-accepted-status .fa.fa-info {border: 1px solid #d90008;color: #d90008;}
.price.manual-delivery-charge-pending-status {color: #ff7600 !important;}
.price.manual-delivery-charge-pending-status .fa.fa-info {border: 1px solid #ff7600;color: #ff7600;}
/*.delivery-address.delivery-zone-inner .zone-info-status .trade-quote-active-label {background: rgb(251, 229, 229);border-radius: 5px;}*/
.tooltip-inner .tooltip-status {font-size: 13px !important; line-height: 15px !important; font-weight: 500; background: transparent !important;}
.background-color-green {background: #ecf5ef;padding: 4px 8px;border-radius: 5px;font-size: 12px;font-weight: 400;}
.grey-bordered-btn .price { color: white !important; }
.add-item-option-list ul li .option-item-box .price-btn .btn.grey-bordered-btn span.price { color: #222324 !important; }
/*========================= Status Css End ========================= */
.no-records {padding: 10px 0;}

@media screen and (max-width:1100px) {
.finishesModal .modal-dialog.modal-dialog-centered { max-width: 600px;}
.seamless-texture-image-modal .modal-dialog {max-width: 600px;}
}
@media screen and (max-width:1000px) {
  .videoPreviewModal .modal-dialog-centered{ padding: 0 15px;}
}
@media screen and (max-width:767px) {
  .videoPreviewModal .modal-dialog-centered{ padding: 0 0;}
  .finishesModal .modal-dialog.modal-dialog-centered { max-width: 100%;}
}

/*================================= Project Status CSS Start ================================= */
.order-progress-meter ul li.active-status .order-progress-item span,
.order-progress-meter ul li.active-status {color: #499d63 !important;}
.order-progress-meter ul li.active-status.delivery-address-required-status .order-progress-item span,
.order-progress-meter ul li.active-status.deposit-payment-due-status .order-progress-item span,
.order-progress-meter ul li.active-status.expired-status .order-progress-item span,
.order-progress-meter ul li.active-status.deposit-payment-due-status,
.order-progress-meter ul li.active-status.deposit-payment-due-status,
.order-progress-meter ul li.active-status.delivery-address-required-status,
.order-progress-meter ul li.active-status.final-payment-pending-status,
.order-progress-meter ul li.active-status.expired-status,
.order-progress-meter ul li.active-status.manual-delivery-quote-required-status,
.order-progress-meter ul li.active-status.quote-expiry-imminent-status,
.order-progress-meter ul li.active-status.deposit-expiry-imminent-status,
.order-progress-meter ul li.disable-status.manual-delivery-quote-required-status{color: #d90008 !important;}
.order-progress-meter ul li.disable-status .order-progress-item span,
.order-progress-meter ul li.disable-status {color: #9cbfad !important;}

.order-progress-meter ul li.goods-despatch-unspecified-status .order-progress-item span,
.order-progress-meter ul li.goods-despatch-unspecified-status,
.order-progress-meter ul li.final-payment-processing-status .order-progress-item span,
.order-progress-meter ul li.final-payment-processing-status,
.order-progress-meter ul li.deposit-payment-processing-status .order-progress-item span,
.order-progress-meter ul li.deposit-payment-processing-status {color: #ff7600 !important;}
.order-progress-meter ul li.deposit-payment-processing-status .order-progress-item span, .order-progress-meter ul li.final-payment-processing-status .order-progress-item span {
  background: #ff7600 !important;
}

.order-progress-meter ul li.client-organized-order-collection-status .order-progress-item span,
.order-progress-meter ul li.client-organized-order-collection-status {color: #499d63 !important;}

.order-progress-meter ul li.active-status.goods-despatch-out-to-tender-status .order-progress-item span,
.order-progress-meter ul li.active-status.goods-despatch-out-to-tender-status,
.order-progress-meter ul li.active-status.manual-delivery-quote-pending-status .order-progress-item span,
.order-progress-meter ul li.active-status.manual-delivery-quote-pending-status,
.order-progress-meter ul li.active-status.goods-despatch-declined-status .order-progress-item span,
.order-progress-meter ul li.active-status.goods-despatch-declined-status {color: #ff7600 !important;}

.expired-status,
.order-progress-meter ul li.goods-despatch-unspecified-status.stock-required-date-pending .order-progress-item span,
.order-progress-meter ul li.goods-despatch-unspecified-status.stock-required-date-pending {color: #d90008 !important;}


/*================================= Project Status CSS End ================================= */
/*================================= Notfound page footer section hide CSS start ================================= */
body.not-found .signup-section { display: none !important; }
body.not-found .none-fixed-content { display: none !important; }
body.not-found main { min-height: inherit }
/*================================= Notfound page footer section hide CSS End ================================= */

::ng-deep .theme-green .bs-datepicker-body table td span.disabled, .theme-green .bs-datepicker-body table td.disabled span{color: #fff !important;}


.order-progress-meter ul li.deposit-payment-processing-status .fa.fa-exclamation, .order-progress-meter ul li.final-payment-processing-status .fa.fa-exclamation {display: inline-block;color: #fff !important;font-size: 10px;padding: 5px 8px !important;border-radius: 50%}
.loader-parent .coustom-loader {position: absolute !important;}
@media screen and (max-width: 1600px){
  .delivery-quotes-title .delivery-quotes-title-description {font-size: 13px;}
}
@media screen and (max-width: 767px) {
  .delivery-quotes-title .delivery-quotes-title-description {font-size: 12px;font-weight: 400;line-height: 22px;}
  .delivery-quotes-status .delivery-quotes-title .delivery-quotes-title-description.mt-3 {margin-top: 10px !important;}
}

/*img {*/
/*  width: 100%;*/
/*  height: auto;*/
/*  aspect-ratio: auto 640 / 360;*/
/*}*/


/* ================== Banner Css start ==================*/
.banner-section-cms-page {
  padding-top: 30px;
}
.banner-section-cms-page .banner-section {
  position: relative;
  height: calc(100vh - 150px);
}

.banner-section-cms-page .banner-section .page-title {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  line-height: 90px;
  font-family: Lexend Deca, sans-serif;
  font-size: 70px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-section-cms-page .banner-section .bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.flooring-listing-title {
  margin-bottom: 40px;
  font-size: 26px;
  color: #222324;
  font-family: Lexend Deca, sans-serif;
  margin-right: 20px;
}

.flooring-image-wrapper .img-fluid {width: 100%;}

@media screen and (max-width: 1399px) {
  .banner-section-cms-page .banner-section .page-title {
    margin-bottom: 40px;
    padding-left: 40px;
  }
}
@media screen and (max-width: 991px){
  .flooring-image-wrapper .img-fluid {margin-bottom: 30px}
  .banner-section-cms-page .banner-section {
    max-height: 500px;
  }
}
@media screen and (max-width: 767px) {
  .banner-section-cms-page .banner-section {
    max-height: 340px;
  }
}
@media screen and (max-width: 480px) {
  .banner-section-cms-page .banner-section .page-title {
    margin-bottom: 20px;
    padding-left: 20px;
  }
}
/* ================== Banner Css End ==================*/

.custom-ng-select.custom-ng-select-ng-template.ng-select {min-width: 260px;}
.custom-ng-select.custom-ng-select-ng-template.ng-select .ng-select-container  {margin-bottom: 0;border: none;}
.custom-ng-select.custom-ng-select-ng-template.ng-select .ng-select-container .custom-label {display: flex;align-items: center;gap: 10px;}
.custom-group-header strong {color: #222324;font-size: 14px;line-height: 30px;font-weight: 700;}
.custom-option {display: flex;align-items: center;gap: 10px;}
.item-details-main {display: flex;align-items: flex-start;flex-direction: column;justify-content: flex-start;}
.item-details-main .item-details {font-weight: 600;}

.wastage-checkbox-main {position: relative;cursor: pointer;-webkit-user-select: none;user-select: none;padding-left: 30px;}
.wastage-checkbox-main input {position: absolute;opacity: 0;cursor: pointer;top: -5px;left: 2px;}
.option-item-box .wastage-checkbox-main .checkmark {position: absolute;border: 1px solid;border-radius: 3px;top: 6px;left: 0;height: 18px;width: 18px;transition: all .7s;}
.wastage-checkbox-main .checkmark:after {content: "";position: absolute;display: none;left: 6px;top: 2px;width: 5px;height: 10px;border: solid white;border-width: 0 2px 2px 0;transform: rotate(45deg);}
.wastage-checkbox-main input:checked ~ .checkmark:after {display: block;}
.wastage-checkbox-main input:checked ~ .checkmark {background-color: #499d63;border: 1px solid transparent;transition: all .35s;}
